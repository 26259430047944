<template>
  <div class="background">
    <navbar/>
    <v-row justify="center" class="container5">
      <v-col cols="12" sm="6" lg="4">
        <v-card class="form-card mb100 mt100">
          <div class="form-header">
            <h2>{{ $t('pageContact.formHeader') }}</h2>
          </div>
          <!-- Form Fields -->
          <form action="" method="get" class="form" @submit.prevent="SendMailContact">
            <v-row>
              <v-col cols="12">
                <label>{{ $t('pageContact.fields.name') }}</label>
                <input type="text" name="name" v-model="nameInput" required>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>{{ $t('pageContact.fields.email') }}</label>
                <input type="text" name="e-mail" v-model="emailInput" required>
              </v-col>
            </v-row>
              <v-row>
                <v-col cols="12">
                  <label>{{ $t('pageContact.fields.company') }}</label>
                  <input type="text" name="societe" v-model="societeInput">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>{{ $t('pageContact.fields.phone') }}</label>
                  <input type="tel" name="telephone" v-model="telephoneInput">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>{{ $t('pageContact.fields.subject') }}</label>
                  <v-select
                    v-model="sujetInput"
                    :items="sujets"
                    label="Choisissez un sujet"
                    required
                  ></v-select>
                  <v-text-field
                    v-if="sujetInput === 'Autre'"
                    v-model="sujetAutre"
                    label="Veuillez préciser votre sujet"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>{{ $t('pageContact.fields.message') }}</label>
                  <textarea name="message" id="message" v-model="messageInput" rows="6" required></textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <button type="submit" class="submit-button">{{ $t('pageContact.submitButton') }}</button>
                </v-col>
              </v-row>
            </form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="successDialog" max-width="400px">
      <v-card class="success-dialog-card">
        <v-card-title class="headline">{{ $t('pageContact.successDialog.title') }}</v-card-title>
        <v-card-text>{{ $t('pageContact.successDialog.content') }}</v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="successDialog = false">{{ $t('pageContact.successDialog.button') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <foot class="mt100"/>
  </div>
</template>

<script>
import foot from '@/components/FooterBis.vue'
import navbar from '@/components/NavBar.vue'
export default {
    components:{
      foot,
      navbar
    },
		name: 'Login',
		data(){
			return {
				nameInput : '',
        emailInput: '',
        societeInput: '',
        messageInput: '',
        successDialog: false,
        telephoneInput: '',
        sujetInput: '',
        sujetAutre: '',
        sujets: [
          'Je souhaite avoir une présentation du système MiceTracking',
          'Je souhaite recevoir les fiches produits',
          'Autre'
        ]
			}
		},
		
		methods: {
      sendhey(){
        console.log('hey')
      },
    async SendMailContact() {
      const url = this.$api.getRESTApiUri() + '/envoie-contact';
      let sujet = this.sujetInput;
      if( sujet === 'Autre'){
        sujet = this.sujetAutre
      }
      const data = {   // Create a regular JavaScript object instead of FormData
        sujet: sujet,
        societe: this.societeInput,
        nom: this.nameInput,
        email: this.emailInput,
        message: this.messageInput,
        tel: this.telephoneInput,
      };
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',  // Set the content type to JSON
          },
          body: JSON.stringify(data), // Convert the data object to a JSON string
        });

        if (response.ok) {
          const result = await response.json(); // Parse the response as JSON
          console.log(result);
          this.successDialog = true;
          this.nameInput = '',
          this.emailInput = '',
          this.societeInput = '',
          this.sujetInput = '',
          this.messageInput = '',
          this.telephoneInput = '',
          console.log(this.successDialog) // Afficher la fenêtre de notification
        } else {
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }


		}
	}

</script>

<style scoped>
.container5 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}

.form-card {
  background-color: none;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}


.form label {
  width: 100%;
}

.form input,
.form textarea {
  width: 100%;
  border: 1px solid var(--grisclair);
	border-radius: 5px;
  background-color: white;
  height: 35px;
  padding: 10px;
  margin-bottom: 15px;
}

.form textarea {
  resize: vertical;
}

.submit-button {
  background-color: var(--bleu);
  color: white !important;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--turquoise);
}


#message{
    height: 250px;
}

.success-dialog-card { /* Set the background color for the dialog */
  border-radius: 10px;
  padding: 20px;
  text-align: center; /* Center the content within the dialog */
}

.success-dialog-card .headline {
  color: var(--bleu); /* Set the color for the headline text */
  font-size: 24px; /* Customize the font size for the headline */
  margin-bottom: 16px; /* Add some space below the headline */
}

.success-dialog-card .v-card-text {
  color: var(--grisclair); /* Set the color for the text content */
  font-size: 18px; /* Customize the font size for the text content */
  margin-bottom: 24px; /* Add some space below the text content */
}

.success-dialog-card .v-card-actions {
  display: flex;
  justify-content: center; /* Center the button within the dialog */
}

.success-dialog-card .v-card-actions .v-btn {
  color: var(--vertclair); /* Set the text color for the button */
  background-color: var(--bleu); /* Set the background color for the button */
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.background {
  position: relative;
  z-index: 1;
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(75deg, var(--bleuclair), var(--vertfonce) );
  filter: blur(10px);
  opacity: 0.5;
}

.success-dialog-card .v-card-actions .v-btn:hover {
  background-color: var(--turquoise); /* Set the background color for the button on hover */
}

@media (max-width: 767px) {
.container5{
  margin-top: 50px;
  margin-bottom: 50px;
}

.form-card{
  margin-left: 5%;
  width: 90%;
}

}
</style>