<template>
    <v-row style="margin-top: 15px;">
		<v-col cols="12" sm="4">
			<div class="box-curves">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Accélération Axe X (g)</v-card-title>
                		<v-checkbox v-model="isContinue" :label="`Continue ?`"></v-checkbox>
						<v-card-title> <button class="button-exp" @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</button></v-card-title>
					</v-col>
				</v-row>
				<line-chart-X :key="chartKey" :chartData="arrAccelerometreX" :options="axeXChartOptions"
					:chartColors="accélérationChartColors" :chartColorsImplant="accélérationImplantChartColors" label="Axe X" :tag_id_name="tag_id_name" :tag_id="tag_id" :pause="isPaused"
					:vib=true
					/>
				<br>	
			</div>
		</v-col>
		<v-col cols="12" sm="4">
			<div class="box-curves">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Accélération Axe Y (g)</v-card-title>
						<v-card-title> <button class="button-exp" @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</button></v-card-title>
					</v-col>
				</v-row>
				<line-chart-Y :key="chartKey" :chartData="arrAccelerometreY" :options="axeYChartOptions"
					:chartColors="accélérationChartColors" :chartColorsImplant="accélérationImplantChartColors" label="Axe Y" :tag_id_name="tag_id_name" :tag_id="tag_id" :pause="isPaused" :vib=true />
				<br>
				<v-divider></v-divider>
			</div>
		</v-col>
		<v-col cols="12" sm="4">
			<div class="box-curves">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Accélération Axe Z (g)</v-card-title>
						<v-card-title> <button class="button-exp" @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</button></v-card-title>
					</v-col>
				</v-row>
				<line-chart-Z :key="chartKey" :chartData="arrAccelerometreZ" :options="axeZChartOptions"
					:chartColors="accélérationChartColors" :chartColorsImplant="accélérationImplantChartColors" label="Axe Z" :tag_id_name="tag_id_name"  :tag_id="tag_id" :pause="isPaused"
					:vib=true
					/>
				<br>
				<v-divider></v-divider>
			</div>
		</v-col>
	</v-row>
</template>


<script>
import { switchToken } from '../../../functions/test-token';
import LineChartX from "./LineChartMultipleTemp.js";
import LineChartY from "./LineChartMultipleTemp.js";
import { convertirDateAvecDecalage } from '../../../functions/convert-date.js';
import LineChartZ from "./LineChartMultipleTemp.js";
export default {
	props:["tag_id", "tag_id_name"],
    name: 'accxyz',
    components: {
			LineChartX,
			LineChartY,
			LineChartZ,	
	},

    data() {
        return {
			isContinue : false,
			chartKey: 0,
			id_memo_x: [],
			id_memo_y: [],
			id_memo_z: [],
			date_memo_x: [],
			date_memo_y: [],
			date_memo_z: [],
			isPaused: false,
            arrAccelerometreX: [],
            arrAccelerometreY: [],
            arrAccelerometreZ: [],
            accélérationImplantChartColors: [
					{
						borderColor: 'rgb(0, 188, 212)',
						backgroundColor: 'rgba(0, 188, 212, 0.2)'
					},
					{
						borderColor: 'rgb(255, 153, 102)',
						backgroundColor: 'rgba(255, 153, 102, 0.2)'
					},
					
					{
						borderColor: 'rgb(150, 123, 200)',
						backgroundColor: 'rgba(150, 123, 200, 0.2)'
					},
					{
						borderColor: 'rgb(157, 180, 63)',
						backgroundColor: 'rgba(57, 180, 63, 0.2)'
					},
				], // différencier capteurs vibration des sphères
			accélérationChartColors: [
					{
						borderColor: 'rgb(217, 50, 50)',
						backgroundColor: 'rgba(217, 50, 50, 0.2)'
					},
					{
						borderColor: 'rgb(140, 83, 62)',
						backgroundColor: 'rgba(140, 83, 62, 0.2)'
					},
					{
						borderColor: 'rgb(239, 99, 99)',
						backgroundColor: 'rgba(239, 99, 99, 0.2)'
					},
					{
						borderColor: 'rgb(166, 129, 96)',
						backgroundColor: 'rgba(166, 129, 96, 0.2)'
					},
					{
						borderColor: 'rgb(239, 99, 99)',
						backgroundColor: 'rgba(239, 99, 99, 0.2)'
					},
				],
            // ===============================  CHART ACC-X ===============================
            axeXChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						zoom: {
							pan: {
								enabled: true,
								mode: 'x',
							},
							zoom: {
								wheel: true,
								enabled: true,
								mode: 'x'
							}
						},
						streaming: {
							frameRate: 30,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 10000,
								ttl: undefined,
								refresh: 150,
								delay: 2000,
								onRefresh: (chart) => {
									for (let i = 0; i < this.tag_id.length; i++) {
										if(this.tag_id[i].startsWith('vib2_') || this.tag_id[i].startsWith('spha') || this.tag_id[i].startsWith('imp_') || this.tag_id[i].startsWith('ppg1') && this.isContinue == true){
											this.getAxeXContinue(i).then((newData) => {
												let g = JSON.parse(JSON.stringify(newData));
												chart.data.datasets[i].data.push(...g);
											}).catch((e) => {
												console.log("[FETCH] Error");
											});
										}else if(this.tag_id[i].startsWith('imp_') || this.tag_id[i].startsWith('ppg1') && this.isContinue == false || this.tag_id[i].startsWith('vib_')){
											this.getAxeXContinue(i).then((newData) => {
												let g = JSON.parse(JSON.stringify(newData));
												chart.data.datasets[i].data.push(...g);
											}).catch((e) => {
												console.log("[FETCH] Error");
											});
										}
									}
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
				// ===============================  CHART AXE Y ===============================
				axeYChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						zoom: {
							pan: {
								enabled: true,
								mode: 'x',
							},
							zoom: {
								wheel: true,
								enabled: true,
								mode: 'x'
							}
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 10000,
								ttl: undefined,
								refresh: 150,
								delay: 2000,
								onRefresh: (chart) => {
									for (let i = 0; i < this.tag_id.length; i++) {
										if(this.tag_id[i].startsWith('vib2_') || this.tag_id[i].startsWith('spha') || this.tag_id[i].startsWith('imp_') || this.tag_id[i].startsWith('ppg1') && this.isContinue == true){
											this.getAxeYContinue(i).then((newData) => {
												let g = JSON.parse(JSON.stringify(newData));
												chart.data.datasets[i].data.push(...g);
											}).catch((e) => {
												console.log("[FETCH] Error");
											});
										}else if(this.tag_id[i].startsWith('imp_') || this.tag_id[i].startsWith('ppg1') && this.isContinue == false || this.tag_id[i].startsWith('vib_')){
											this.getAxeYContinue(i).then((newData) => {
												let g = JSON.parse(JSON.stringify(newData));
												chart.data.datasets[i].data.push(...g);
											}).catch((e) => {
												console.log("[FETCH] Error");
											});
										}
									}
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
				// ===============================  CHART AXE Z ===============================
				axeZChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						zoom: {
							pan: {
								enabled: true,
								mode: 'x',
							},
							zoom: {
								wheel: true,
								enabled: true,
								mode: 'x'
							}
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 10000,
								ttl: undefined,
								refresh: 150,
								delay: 2000,
								onRefresh: (chart) => {
									for (let i = 0; i < this.tag_id.length; i++) {
										if(this.tag_id[i].startsWith('vib2_') || this.tag_id[i].startsWith('spha') || this.tag_id[i].startsWith('imp_') || this.tag_id[i].startsWith('ppg1') && this.isContinue == true){
											this.getAxeZContinue(i).then((newData) => {
												let g = JSON.parse(JSON.stringify(newData));
												chart.data.datasets[i].data.push(...g);
											}).catch((e) => {
												console.log("[FETCH] Error");
											});
										}else if(this.tag_id[i].startsWith('imp_') || this.tag_id[i].startsWith('ppg1') && this.isContinue == false || this.tag_id[i].startsWith('vib_')){
											this.getAxeZContinue(i).then((newData) => {
												let g = JSON.parse(JSON.stringify(newData));
												chart.data.datasets[i].data.push(...g);
											}).catch((e) => {
												console.log("[FETCH] Error");
											});
										}
									}
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
                
			};
		},
		methods: {
			async getAxeXContinue(i) {
				// console.log('heyyyy')
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url = this.$api.getRESTApiUri() + `/axe_x_tag/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_x_tag.length === 0) {
							newData.push({
								x: Date.now(),
							});
							return newData;
						}
						if (data[0].id <= this.id_memo_x[i]) {
							newData.push({
								x: Date.now(),
							});
						} else {
							if (this.date_memo_x[i] != null) {
								// const date_now = new Date(data[0].received_on).getTime() - 1 * 60 * 60 * 1000;
								const date_now = Date.now();
								const duration = data[0].tps_mesure;
								// console.log(duration)
								const frequency = duration / data[0].acc_x_tag.length;
								console.log(frequency)
								for (let j = 0; j < data[0].acc_x_tag.length; j++) {
									newData.push({
										y: data[0].acc_x_tag[j],
										x: date_now - duration + j * frequency,
									});
								}
							}
							this.date_memo_x[i] = data[0].received_on;
						}
						this.id_memo_x[i] = data[0].id;
						return newData;
					})
					.catch((error) => {
						console.log(error);
					});
				},

			async getAxeYContinue(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_y_tag/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_y_tag.length === 0) {
							newData.push({
								x: Date.now(), 
							});
							return newData;
						}
						if(data[0].id <= this.id_memo_y[i]){
							newData.push({
								x: Date.now(), 
							});
						}else{
							if (this.date_memo_y[i] != null){
								const durée = data[0].tps_mesure;
								const frq = durée/data[0].acc_y_tag.length
								// const date_now =  new Date(data[0].received_on).getTime() - 1 * 60 * 60 * 1000;
								const date_now = Date.now();
								for (let i = 0; i <= data[0].acc_y_tag.length; i++) {
									newData.push({
										y: data[0].acc_y_tag[i],
										x: date_now - durée + i * frq,
									});
									if(i == 0 ){
										const x = new Date(this.date_memo_y[i]).getTime() - 1 * 60 * 60 * 1000
										const y = date_now - durée + i * frq
										
										if(y<x ){
											console.log(tag_id)
											console.log("date(n) - temps de mesures :" + y)
											console.log("date(n-1) : " + x)
											console.log(y - x)
										}
									}
								}
							}
							this.date_memo_y[i] = data[0].received_on;
							
						}
						this.id_memo_y[i]  = data[0].id
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},
			async getAxeZContinue(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_z_tag/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_z_tag.length === 0) {
							newData.push({
								x: Date.now(), 
							});
							return newData;
						}
						if(data[0].id <= this.id_memo_z[i]){
							newData.push({
								x: Date.now(), 
							});
						}else{
							if (this.date_memo_z[i] != null){
								// const date_now = new Date(data[0].received_on).getTime() - 1 * 60 * 60 * 1000;
								const date_now = Date.now();
								const durée = data[0].tps_mesure;
								// console.log(durée)
								const frq = durée/data[0].acc_z_tag.length
								// console.log(frq)
								for (let i = 0; i < data[0].acc_z_tag.length; i++) {
									newData.push({
										y: data[0].acc_z_tag[i],
										x: date_now - durée + i * frq,
									});
								}
							}
							this.date_memo_z[i] = data[0].received_on;
							
						}
						this.id_memo_z[i]  = data[0].id
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},

			async getAxeX(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_x_tag/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						// console.log(data)
						let newData = [];
						if (data[0].acc_x_tag.length === 0) {
							return [];
						}
						if(data[0].id <= this.id_memo_x[i]){
							newData.push({
								x: Date.now(), 
							});
						}else{
							const frq = 250/data[0].acc_x_tag.length
							for(let i = 0; i<data[0].acc_x_tag.length; i++){
								newData.push({
									y: data[0].acc_x_tag[i],
									x: Date.now() + i*frq, 
								});
							}
						}
						this.id_memo_x[i]  = data[0].id
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},
			async getAxeY(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_y_tag/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_y_tag.length === 0) {
							return [];
						}
						if(data[0].id <= this.id_memo_y[i]){
							newData.push({
							x: Date.now(), 
							});
						}else{
							const frq = 250/data[0].acc_y_tag.length
							for(let i = 0; i<data[0].acc_y_tag.length; i++){
								newData.push({
									y: data[0].acc_y_tag[i],
									x: Date.now() + i*frq, 
								});
							}
						}
						this.id_memo_y[i]  = data[0].id
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},
			async getAxeZ(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_z_tag/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_z_tag.length === 0) {
							return [];
						}
						if(data[0].id <= this.id_memo_z[i]){
							newData.push({
							x: Date.now(), 
							});
						}else{
							const frq = 250/data[0].acc_z_tag.length
							for(let i = 0; i<data[0].acc_z_tag.length; i++){
								newData.push({
									y: data[0].acc_z_tag[i],
									x: Date.now() + i*frq, 
								});
							}
						}
						this.id_memo_z[i]  = data[0].id
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},

			forceChartRerender() {
				this.chartKey += 1; // Incrémenter la clé pour forcer le re-rendu
			},
			togglePause() {
				this.isPaused = !this.isPaused;
			},
		}
	};
</script>

