<template>
    <div style="margin-top: 15px;">
		<div class="box-curves">
			<v-row>
				<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Position des Tags dans la cage</v-card-title>
						<v-card-title ><button class="button-exp" @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</button></v-card-title>
					</v-col>
			</v-row>
			<line-chart :chartData="arrPosition" :options="positionChartOptions" :chartColors="turquoiseChartColors"
				label="Heart Rate" :tag_id_name="tag_id_name" :pause="isPaused"/>
			<br>
		</div>
    </div>
</template>

<script>
import { getDataLastId } from '../../../functions/get-data'
import LineChart from "./LineChartMultiple.js";

export default {
	props:["tag_id", "tag_id_name"],	
    components: {
		LineChart,
	},
    data() {
			return {	
				id_memo: [],	
				isPaused: false,
				arrPosition: [],
				turquoiseChartColors: {
					borderColor: 'rgb(0, 188, 212)',
					backgroundColor: 'rgba(0, 188, 212, 0.2)'
				},
				
                positionChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 30,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 1000,
								delay: 2000,
								onRefresh: (chart) => {
								for (let i = 0; i < this.tag_id.length; i++) {
									this.getBpm(i).then((newData) => {
										let g = JSON.parse(JSON.stringify(newData));
										chart.data.datasets[i].data.push(...g);
									}).catch((e) => {
										console.log("[FETCH] Error");
									});
								}
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
							ticks: {
								min: 1,        // Valeur minimale fixée à 1
								max: 40,       // Valeur maximale fixée à 40
								stepSize: 1,   // Afficher uniquement les valeurs entières
								precision: 0   // Éviter les chiffres après la virgule
							}
						}]
					},
					legend: {
						display: true
					},
				},
                
			};
		},
		methods: {
            async getBpm(i) {
				try {
					const data = await getDataLastId(this.tag_id[i])
					let newData = [];
					if(data[0].id <= this.id_memo[i]){
						this.id_memo[i]  = data[0].id
						return [];
					}else{
						const x = data[0].pos_x_tag;
						const y = data[0].pos_y_tag;
						const position = x*5+y+1;
						
						newData.push({
							x: Date.now(),
							y: position,
						});
					}
					this.id_memo[i]  = data[0].id

					return newData;
				} catch (error) {
					console.log(error);
					// Renvoyer une erreur ou une valeur par défaut en cas d'erreur
					// return []; // Par exemple, renvoyer un tableau vide en cas d'erreur
				}
			},

			togglePause() {
				this.isPaused = !this.isPaused;
			},
		}
	};
			
</script>