<template>
    <div>
        <navbar/>
        <mice-cloud-image/>
        <mice-cloud-presentation class="mt100"/>
        <mice-cloud-fonctionnalites class="mt100"/>
        <mice-cloud-video class="mt100"/>
        <bulle-contact/>
        <foot class="mt100"/>
    </div>
</template>


<script>
import foot from '@/components/FooterBis.vue'
import navbar from '@/components/NavBar.vue'
import MiceCloudPresentation from '../../components/PageMicecloud/MiceCloudPresentation.vue';
import MiceCloudImage from '../../components/PageMicecloud/MiceCloudImage.vue';
import MiceCloudFonctionnalites from '../../components/PageMicecloud/MiceCloudFonctionnalites.vue';
import MiceCloudVideo from '../../components/PageMicecloud/MiceCloudVideo.vue';
import BulleContact from '../../components/BulleContact.vue';
export default {
    components:{
      foot,
      navbar,
      MiceCloudImage,
      MiceCloudPresentation,
      MiceCloudFonctionnalites,
      MiceCloudVideo,
      BulleContact
    },
    metaInfo: {
        title: 'MiceTracking - MiceCloud, Plateforme logicielle de suivi de santé animale',
        meta: [
            {
                name: 'description',
                content: 'Découvrez la plateforme logicielle de MiceTracking pour un suivi précis et interactif des souris. Analisez l\'ammoniaque, la température, les mouvements, et bien plus pour garantir le bien-être de vos souris'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, plateforme logicielle, MiceCloud, analyse, suivi en temps réel, courbes, export CSV, laboratoire, souris, interaction capteurs/animal'
            }
        ]
    },
}
</script>