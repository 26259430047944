<template>
   <div style="margin-top: 15px;">
        <v-col>
            <div class="box-curves">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Humidité (RH%)</v-card-title>
						<v-card-title ><button class="button-exp" @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</button></v-card-title>
					</v-col>
				</v-row>
        <line-chart :key="chartKey" :chartData="arrTemperature" :options="TemperatureChartOptions" :chartColors="humiditéChartColors" :tag_id_name="tag_id_name" :tag_id="tag_id" :pause="isPaused" />
        <br>
      </div>
    </v-col>
  </div>
</template>

<script>
import LineChart from "./LineChartMultiple.js";
import getDataLastId from "../../../functions/get-data.js"

export default {
	props:["tag_id", "tag_id_name"],	
    components: {
			LineChart,
		},
    data() {
		return {
			chartKey: 0,
			id_memo : [],		
			arrTemperature: [],
			val_i: 0,
			humiditéChartColors: [
				{
					borderColor: 'rgb(2, 195, 154)',
					backgroundColor: 'rgba(2, 195, 154, 0.2)'
				},
				{
					borderColor: 'rgb(23, 99, 83)',
					backgroundColor: 'rgba(23, 99, 83, 0.2)'
				},
				{
					borderColor: 'rgb(98, 217, 50)',
					backgroundColor: 'rgba(98, 217, 50, 0.2)'
				},
			],
			isPaused: false,
			TemperatureChartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					streaming: {
						frameRate: 30,
					}
				},
				scales: {
					xAxes: [{
						// display: 'auto',
						type: 'realtime',
						time: {
							displayFormats: {
								millisecond: 'HH:mm:ss',
								second: 'HH:mm:ss',
								minute: 'HH:mm:ss',
								hour: 'HH:mm:ss',
								day: 'HH:mm:ss',
								week: 'HH:mm:ss',
								month: 'HH:mm:ss',
								quarter: 'HH:mm:ss',
								year: 'HH:mm:ss'
							},
							tooltipFormat: 'HH:mm:ss'
						},	
						realtime: {
							duration: 5000,
							ttl: undefined,
							refresh: 1000,
							delay: 2000,
							onRefresh: (chart) => {
								for (let i = 0; i < this.tag_id.length; i++) {
									this.getHumi(i).then((newData) => {
										let g = JSON.parse(JSON.stringify(newData));
										chart.data.datasets[i].data.push(...g);
									}).catch((e) => {
										console.log("[FETCH] Error");
									});
								}
							},
						}
					}],
					yAxes: [{
						type: 'linear',
						offset: true,
					}]
				},
				legend: {
					display: true
				},
			},
		};
		},
		methods: {
            async getHumi(i) {
				const newData = []; // Déclarer le tableau à l'extérieur de la fonction
				
				try {
					const data = await getDataLastId()
					if(data[0].id <= this.id_memo[i]){
							newData.push({
							x: Date.now(), 
						});
						}else{
							newData.push({
								x: Date.now(), 
								y: data[0].tag_humidity, 
							});
						}
					this.id_memo[i]  = data[0].id
				} catch (error) {
					console.error(error.message);
					throw new Error('Erreur lors de l\'appel à l\'API'); // Lever une erreur générique en cas d'erreur
				}
				return newData;
			},
			forceChartRerender() {
				console.log('hey ^^')
				this.chartKey += 1; // Incrémenter la clé pour forcer le re-rendu
			},
			togglePause() {
				this.isPaused = !this.isPaused;
			},
		}
	};
</script>

