import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	// ***********************************************************************************
	// ********************************** PARTIE MICE CLOUD *********************************
	// ***********************************************************************************
		path: '/login',
		name: 'Login',
		component: () => import(  '../views/Login.vue'),
	},
	{
		path: '/courbes-matrices-tag',
		name: 'courbes matrices et tag',
		component: () => import(  '../views/cloud/CourbesRealTime.vue'),
		meta: { requiresAuth: false, requiresActivity : true }
	},
	{
		path: '/matrice',
		name: 'matrice',
		component: () => import(  '../views/cloud/Matrice2D.vue'),
		meta: { requiresAuth: false, requiresActivity : true  }
	},
	{
		path: '/tableau/tags',
		name: 'export csv hub',
		component: () => import(  '../views/cloud/ExportData.vue'),
		meta: { requiresAuth: false, requiresActivity : true  }
	},
	{
		path: '/experience',
		name: 'Experience',
		component: () => import(  '../views/cloud/Experiences.vue'),
		meta: { requiresAuth: false, requiresActivity : true  }
	},
	{
		path: "/account",
		name: "my account",
		component: () => import( "../views/cloud/Account.vue"),
		meta: { requiresAuth: false, requiresActivity : true  }
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: () => import( '../views/ResetPassword.vue')
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: () => import( '../views/ForgotPassword.vue')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import(  '../views/Register.vue')
	},
	{
		path: '/Home',
		name: 'tableau-de-bord',
		component: () => import(  '../views/cloud/TableauDeBord.vue'),
		meta: { requiresAuth: true, requiresActivity : true  }
	},
	// ***********************************************************************************
	// ********************************** PARTIE ADMIN   *********************************
	// ***********************************************************************************
	
	{
		path: '/admin/creation-attribution',
		name: 'creation attribution',
		component: () => import(  '../views/admin/CreationAttribution.vue'),
		meta: { requiresAdminAuth: true, requiresActivity : true  }
	},

	{
		path: '/admin/liste-utilisateurs',
		name: 'liste des utilisateurs',
		component: () => import(  '../views/admin/ListUsers.vue'),
		meta: { requiresAdminAuth: true, requiresActivity : true  }
	},
	{
		path: '/admin/liste-materiel',
		name: 'liste du materiel',
		component: () => import(  '../views/admin/ListMateriel.vue'),
		meta: { requiresAdminAuth: true, requiresActivity : true  }
	},
	{
		path: '/admin/register-token-creation',
		name: 'creation token',
		component: () => import(  '../views/admin/TokenRegisterCreation.vue'),
		meta: { requiresAdminAuth: true, requiresActivity : true  }
	},
	
	// ***********************************************************************************
	// ********************************** PARTIE VITRINE *********************************
	// ***********************************************************************************
	
	{
    path: "/",
    name: "Home",
    component: () =>
		import("../views/vitrine/PageAccueil.vue"),
		meta: { requiresCodeAuth: true }
  	},
  {
	path: '/products/matrice',
    name: "produits matrices",
    component: () =>
      import("../views/vitrine/PagePlateformes.vue"),
	  meta: { requiresCodeAuth: true }
  },
  {
    path: "/products/implants",
    name: "produits implants",
	component: () =>
	import("../views/vitrine/PageImplants.vue"),
	meta: { requiresCodeAuth: true }
},
{
	path: "/contact",
	name: "contact",
	component: () =>
	import("../views/vitrine/PageContact.vue"),
},
{
	path: "/logiciel/micecloud",
	name: "micecloud",
	component: () =>
	import("../views/vitrine/PageMiceCloud.vue"),
	meta: { requiresCodeAuth: true }
},
	{
	path: "/products/actionneurs-capteurs",
	name: "ac",
	component: () =>
	import("../views/vitrine/pageActionneurs.vue"),
	meta: { requiresCodeAuth: true }
},
{
	path: "/code-access",
	name: "code-access",
	component: () =>
	import( "../views/CodeForm.vue"),
},
{
    path: "/mission",
    name: "mission",
    component: () =>
		import( "../views/vitrine/PageMission.vue"),
		meta: { requiresCodeAuth: true}
},
{
    path: "/downloadpdf",
    name: "pdf",
    component: () =>
		import( "../views/DownloadPDF.vue"),
},
]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) { 
		return { x: 0, y: 0 }
	}
})

import {testToken, testCode, adminTest} from '../functions/test-token'

router.beforeEach(async (to, from, next) => {
    const isAuthenticated = await testToken();
    let admin = adminTest();
    let isCodeAuthenticated = await testCode();
    const testCode1 = test(isCodeAuthenticated);
    if (to.meta.requiresAuth && isAuthenticated === false) {
        next({ name: 'Login' });
    }
    if (to.meta.requiresCodeAuth && !testCode1) {
        next({ name: 'code-access' });
    } 
	else if (to.meta.requiresAdminAuth && admin == false) {
        next({ name: 'user-interface' });
    } 
    else {
        next();
    }
});

function test(x) {
  return String(x) == 'true';
}

export default router