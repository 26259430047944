import { render, staticRenderFns } from "./AccueilAvantages.vue?vue&type=template&id=479f6e64&scoped=true&"
import script from "./AccueilAvantages.vue?vue&type=script&lang=js&"
export * from "./AccueilAvantages.vue?vue&type=script&lang=js&"
import style0 from "./AccueilAvantages.vue?vue&type=style&index=0&id=479f6e64&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479f6e64",
  null
  
)

/* custom blocks */
import block0 from "./AccueilAvantages.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Fcdnjs.cloudflare.com%2Fajax%2Flibs%2Faos%2F2.3.4%2Faos.css"
if (typeof block0 === 'function') block0(component)

export default component.exports