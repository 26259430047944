<template>
  <div>
    <v-row>
      <v-col cols="12" sm="0" lg="1" xl="2"></v-col>
      <v-col cols="12" sm="12" lg="10" xl="8">
        <div class="title-container">
          <h2 style="text-align: right">{{ $t('pageActionneurs.howItWorksTitle') }}</h2>
        </div>
        <div class="btn-container">
          <button class="scroll-btn left" @click="scrollLeft">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M21.559,12.062 L15.618,17.984 L21.5221,23.944 C22.105,24.533 22.1021,25.482 21.5131,26.065 C21.2211,26.355 20.8391,26.4999987 20.4571,26.4999987 C20.0711,26.4999987 19.6851,26.352 19.3921,26.056 L12.4351,19.034 C11.8531,18.446 11.8551,17.4999987 12.4411,16.916 L19.4411,9.938 C20.0261,9.353 20.9781,9.354 21.5621,9.941 C22.1471,10.528 22.1451,11.478 21.5591,12.062 L21.559,12.062 Z">
              </path>
            </svg>
          </button>
          <button class="scroll-btn right" @click="scrollRight">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
              <path
                d="M23.5587,16.916 C24.1447,17.4999987 24.1467,18.446 23.5647,19.034 L16.6077,26.056 C16.3147,26.352 15.9287,26.4999987 15.5427,26.4999987 C15.1607,26.4999987 14.7787,26.355 14.4867,26.065 C13.8977,25.482 13.8947,24.533 14.4777,23.944 L20.3818,17.984 L14.4408,12.062 C13.8548,11.478 13.8528,10.5279 14.4378,9.941 C15.0218,9.354 15.9738,9.353 16.5588,9.938 L23.5588,16.916 L23.5587,16.916 Z">
              </path>
            </svg>
          </button>
        </div>
        <div class="fonctionnement-container" ref="container">
          <!-- Étape 01 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACPositionnement.png" alt="">
              <h3>{{ $t('pageActionneurs.steps.installation.stepNumber') }}</h3>
            </div>
            <h5>{{ $t('pageActionneurs.steps.installation.title') }}</h5>
            <p>{{ $t('pageActionneurs.steps.installation.description') }}</p>
          </div>

          <!-- Étape 02 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACProgrammation.png" alt="">
              <h3>{{ $t('pageActionneurs.steps.programming.stepNumber') }}</h3>
            </div>
            <h5>{{ $t('pageActionneurs.steps.programming.title') }}</h5>
            <p>{{ $t('pageActionneurs.steps.programming.description') }}</p>
          </div>

          <!-- Étape 03 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACCollecteInteraction.png" alt="">
              <h3>{{ $t('pageActionneurs.steps.dataCollection.stepNumber') }}</h3>
            </div>
            <h5>{{ $t('pageActionneurs.steps.dataCollection.title') }}</h5>
            <p>{{ $t('pageActionneurs.steps.dataCollection.description') }}</p>
          </div>

          <!-- Étape 04 -->
          <div class="bulle-container">
            <div class="row-between">
              <img src="@/assets/illustrations/ACObservation.png" alt="">
              <h3>{{ $t('pageActionneurs.steps.observation.stepNumber') }}</h3>
            </div>
            <h5>{{ $t('pageActionneurs.steps.observation.title') }}</h5>
            <p>{{ $t('pageActionneurs.steps.observation.description') }}</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="0" lg="1" xl="2"></v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    // Méthode pour défiler vers la gauche
    scrollLeft() {
      const container = this.$el.querySelector('.fonctionnement-container');
      container.scrollBy({
        left: -300, // Distance à défiler
        behavior: 'smooth',
      });
    },
    // Méthode pour défiler vers la droite
    scrollRight() {
      const container = this.$el.querySelector('.fonctionnement-container');
      container.scrollBy({
        left: 300, // Distance à défiler
        behavior: 'smooth',
      });
    },
  },
  
};

</script>

<style scoped>

.title-container{
  width: 100%;
  display: flex;
  justify-content: right;
  margin-bottom: 50px;
  text-align: right
}

.title-container h2{
  width: 60%;
}

.fonctionnement-container {
    display: flex;
    width: 100%; /* Largeur fixe pour l'exemple */
    align-items: center;
    height: 440px;
    overflow-x: hidden; /* Ajoute le défilement horizontal */
    overflow-y: hidden; /* Désactive le défilement vertical si non nécessaire */
    -webkit-overflow-scrolling: touch; /* Permet le défilement fluide sur iOS */
    scroll-behavior: smooth; /* Défilement fluide */
    gap: 50px; /* Permet le défilement fluide sur iOS */
    padding: 0 20px;
    margin-right: 20px;
}

.bulle-container {
  width: 380px;
  height: 320px;
  background-color: var(--grisclair);
  border-radius: 20px;
  padding: 20px;
  flex-shrink: 0; /* Empêche la réduction de la taille des éléments */
  transition: transform 0.3s ease;
  overflow: hidden;
}

.bulle-container div{
  width: 100%;
  height: 100px;
  align-items: end;
}

.bulle-container div img{
  height: 100%;
  max-width: 75%;
}

.bulle-container div h3{
  color: var(--vertfonce);
  font-weight: 600;
  font-size: 56px;
}

.bulle-container h5{
  margin-top: 20px;
}

.bulle-container p{
  margin-top: 15px;
}

.bulle-container:hover {
  transform: scale(1.05); /* Agrandit la div de 5% */
  z-index: 10; /* Met l'élément agrandi au premier plan */
}

.btn-container{
  position: relative;
  top: 20px;
}

.scroll-btn {
  background-color: var(--grisclair);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
  align-items: center;
  transition: opacity 0.3s ease;
}

.scroll-btn:hover {
  opacity: 0.5;
}


.scroll-btn.left {
  left: -40px; /* Ajustez la position de la flèche gauche */
}

.scroll-btn.right {
  right: -20px; /* Ajustez la position de la flèche droite */
}


@media (max-width: 960px) {
  .bulle-container{
    width: 300px;
  }
  .fonctionnement-container{
    overflow-x: auto; /* Ajoute le défilement horizontal */
  }
  .title-container h2{
    width: 100%;
  }
}

</style>
