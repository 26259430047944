<template>
    <div style="padding-left: 60px">
        <navbar/>
        <side-bar/>
        <button class="button-exp mt50" @click.stop="Dialog=true"><v-icon>mdi-creation</v-icon> Nouvelle étude</button>
        <v-data-table
            :headers="headers"
            :items="historyExperiments"
            class="mt50 "
            :sort-by="['start_date']"
            :sort-desc="[true]"
            style="background-color: transparent;"
        >
            <template v-slot:item.tags="{ item }">
                <v-chip-group
                    column
                >
                    <v-chip v-for="tag in item.tags" :key="tag">
                    {{ tag }}
                    </v-chip>
                </v-chip-group>
            </template>
            
            <template v-slot:item.start_date="{ item }">
            {{ formatDate(item.start_date) }}
            </template>
            <template v-slot:item.end_date="{ item }">
            {{ formatDate(item.end_date) }}
            </template>
            <template v-slot:item.duration="{ item }">
            {{ formatDuree(item.end_date, item.start_date, item.statut) }}
            </template>
            <template v-slot:item.action="{ item, index }">
             <div class="row-between">
                <button title="Supprimer l'expérience" @click.stop="itemDelete = item, indexDelete = index, DialogDelete = true"><v-icon>mdi-delete</v-icon></button>
                <button title="Exporter en CSV"  @click.stop="ExportExp(item)"><v-icon>mdi-file-export</v-icon></button>
                <button title="Observer les courbes"  @click.stop="AnalyseExp(item)"><v-icon>mdi-chart-line</v-icon></button>
                <button title="Arrêter" v-if="item.statut == 'en cours'" @click.stop="StopExp(item)"><v-icon>mdi-stop</v-icon></button>
             </div>
            </template>
        </v-data-table>

        <v-dialog v-model="Dialog" width="700">
            <div style="background-color: white; padding: 40px; border-radius: 20px;">
                <div class="row-around">
                    <h2>Nouvelle étude</h2>
                </div>
                <v-row>
                    <v-col col="12" sm="12">
                        <v-text-field
                        v-model="NameExperience"
                        label="Nom de l'étude"
                    ></v-text-field>
                </v-col>
                </v-row>
                <v-row>
                    <v-col col="12" sm="6">
                        <v-select v-model="matrice_selected" :items="matrice_id"  label="Select Matrice" chips persistent-hint @change="getTagAvailable"></v-select>
                    </v-col>
                    <v-col col="12" sm="6">
                        <v-select v-model="tag_selected" :items="tag_available" multiple label="Select Tag" chips persistent-hint></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12" sm="12">
                        <v-slider
                            v-model="durée_selected"
                            :min="1"
                            :max="24"
                            :step="1"
                            thumb-label
                            class="mt-5"
                            :label="`durée (${durée_selected} heures)`"
                        ></v-slider>
                    </v-col>
                </v-row>
                <v-checkbox v-model="confidentiel" :label="`Ne pas partager l'expérience avec les autres utilisateurs possèdant la matrice ${confidentiel}`">confidentiel</v-checkbox>
                <div style="width: 100%; text-align: right;">
                    <button v-if="tag_selected && matrice_selected && NameExperience.length" class="button-exp mt50" @click="SetNewExperience()">Lancer nouvelle étude</button>
                </div>
            </div>
        </v-dialog>
        <apexcharthistorical v-if="showCurves" v-bind:experience="experimentTonAnalyse"/>
        <heatmap v-if="showCurves" v-bind:experience="experimentTonAnalyse"/>
        <v-dialog v-model="DialogDelete" max-width="290">
			<v-card>
				<v-card-title class="headline">Confirmation</v-card-title>
				<v-card-text>
					Êtes-vous sûr(e) de vouloir supprimer l'expérience ?
				</v-card-text>
				<v-card-actions>
					<v-btn color="blue darken-1" text @click="DeleteExp">Oui</v-btn>
					<v-btn color="blue darken-1" text @click="DialogDelete = false">Non</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-dialog v-model="showProgessBar" max-width="450" persistent style="background-color: rgba(0, 0, 0, 0.5);">
            <v-progress-linear :color="isError ? 'red' : 'green'" indeterminate style="height: 8px;"></v-progress-linear>
            <div :style="{ width: '100%', textAlign: 'center', marginTop: '15px', color: isError ? 'red' : 'white' }">
                <p>{{ message }}</p>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import { switchToken } from '../../functions/test-token';
import { getMatriceAvailable, getTags, getDataForExport } from '@/functions/get-data';
import { formatDuration, formatDate } from '../../functions/format';
import navbar from '@/components/NavBarCloud.vue';
import SideBar from '@/components/SideBar'
import apexcharthistorical from '@/components/cloud/PageExperience/ApexChartHistorical.vue'
import heatmap from '@/components/cloud/PageExperience/heatmap.vue'
import moment from 'moment';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
moment.locale('en');

export default{
    components: {
		SideBar,
        navbar,
        apexcharthistorical,
        heatmap
	},
    data(){
        return {
            confidentiel : false,
            Dialog : false,
            DialogDelete : false,
            showCurves : false,
            showProgessBar: false,
            indexDelete : null,
            isError: false,
            message: '',
            itemDelete: [],
            headers: [
                { text: 'Nom de l\'expérience', value: 'name_of_experiment'},
                { text: 'Utilisateur', value: 'user'},
                { text: 'Début de l\'expérience', value: 'start_date', sortable: true},
                { text: 'Fin de l\'expérience', value: 'end_date'},
                { text: 'Durée', value: 'duration'},
                { text: 'Matrice utilisée', value: 'matrice'},
                { text: 'Tags utilisés', value: 'tags'},
                { text: 'Statut', value: 'statut', sortable: false},
                { text: 'Action', value: 'action'},
            ],
            historyExperiments: [],
            matrice_selected : '',
            matrice_id : [],
            matrice_name : [],
            matrice_id_name : [],
            implant: [],
            implant_name: [],
            capteur_amonniaque : [],
            capteur_amonniaque_name : [],
            capteur_vibration : [],
            capteur_vibration_name :[],
            capteur_sphere : [],
            capteur_sphere_name : [],
            capteur_environnement : [],
            capteur_environnement_name : [],
            tag_available: [],
            tag_selected: [],
            tag_name_available: [],
            tagsNameAndIds : {},
            durée_selected: 0,
            NameExperience: '',
            experimentTonAnalyse: null,
        }
    },
    
    methods: {
        async getMatriceAvailable(){
            const user = JSON.parse(localStorage.getItem('user'))
            const accessToken = localStorage.getItem('accessToken');
            const email = user.email
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
                .then(res => {
                if (res.status === 401) {
                    switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                        this.getMatriceAvailable();
                    });
                }
                return res.text();
                })
            .then(async (result) => {
                const data = JSON.parse(result);
                
                this.matrice_id = data.matrice_attributed
                this.matrice_name = data.matrice_renamed
                if (this.matrice_id && this.matrice_id.length > 0) {
                    for(let i=0 ; i< this.matrice_id.length ; i++){
                    if(this.matrice_name[i] == "noname"){
                        this.matrice_id_name.push(this.matrice_id[i])
                    }else{
                        this.matrice_id_name.push(this.matrice_name[i])
                    }
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        async GetTags(){
            const user = JSON.parse(localStorage.getItem('user'))
            const accessToken = localStorage.getItem('accessToken');
            const email = user.email
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
                .then(res => {
                if (res.status === 401) {
                    switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                        this.GetTags();
                    });
                }
                return res.text();
                })
            .then(async (result) => {
                const data = JSON.parse(result);
                this.implant = data.tag_attributed
                this.implant_name = data.tag_renamed
                this.capteur_amonniaque = data.capteur_amonniaque;
                this.capteur_amonniaque_name = data.amonniaque_renamed;
                this.capteur_vibration = data.capteur_vibration;
                this.capteur_vibration_name = data.vibration_renamed;
                this.capteur_sphere = data.capteur_sphere;
                this.capteur_sphere_name = data.sphere_renamed;
                this.capteur_environnement = data.capteur_environnement;
                this.capteur_environnement_name = data.environnement_renamed;
            })
            .catch(error => {
                console.log(error)
            });
        },
        async getTagAvailable() {
            const matrice = encodeURIComponent(this.matrice_selected);
            const url = this.$api.getRESTApiUri() + `/real-time/check-tag-available/${matrice}`;
            const accessToken = localStorage.getItem('accessToken');
            const headers = new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
              .then(res => {
                if (res.status === 401) {
                  switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                    this.getTagAvailable();
                  });
                }
                return res.text();
              })
              .then((result) => {
                const data = JSON.parse(result)
                // Filtrer les éléments du tableau data.tag_available
                this.tag_available = data.tag_available.filter(tag => {
                    // Vérifier si le tag est présent dans au moins un des tableaux cibles
                    return (
                        this.capteur_amonniaque.includes(tag) ||
                        this.capteur_vibration.includes(tag) ||
                        this.capteur_sphere.includes(tag) ||
                        this.capteur_environnement.includes(tag) ||
                        this.implant.includes(tag)
                    );
                });
                this.tag_name_available = [];
                for(let i =0; i < this.tag_available.length; i++){
                    if(this.tag_available[i].startsWith('imp_')){
                        if(this.implant_name[this.implant.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.implant_name[this.implant.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('ppg1')){
                        if(this.implant_name[this.implant.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.implant_name[this.implant.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('env_')){
                        if(this.capteur_environnement_name[this.capteur_environnement.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_environnement_name[this.capteur_environnement.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('vib_')){
                        if(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('vib2_')){
                        if(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_vibration_name[this.capteur_vibration.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('sph_')){
                        if(this.capteur_sphere_name[this.capteur_sphere.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_sphere_name[this.capteur_sphere.indexOf(this.tag_available[i])])
                        }
                    }else if(this.tag_available[i].startsWith('amq_')){
                        if(this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.tag_available[i])] == "noname"){
                            this.tag_name_available.push(this.tag_available[i])
                        }else{
                            this.tag_name_available.push(this.capteur_amonniaque_name[this.capteur_amonniaque.indexOf(this.tag_available[i])])
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });
        },

        async SetNewExperience(){
            const user = JSON.parse(localStorage.getItem('user'))
            const end_date = Date.now() +  this.durée_selected * 3600000
            console.log(end_date);
            const accessToken = localStorage.getItem('accessToken');
				const url = this.$api.getRESTApiUri() + `/set/new-experience`
				return fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${accessToken}`
						},
						body: JSON.stringify({
                            user: user.username,
                            email : user.email,
							name_of_experiment: this.NameExperience,
                            start_date: Date.now(),
                            matrice: this.matrice_selected,
							tags : this.tag_selected,
                            duration : this.durée_selected,
                            end_date : end_date,
                            confidential : this.confidentiel,
						}),
				})
				 .then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
							// Refaites la requête avec le nouveau token
							this.SetNewExperience();
						});
					}
					return res.text();
				})
				.then((result) => {
                    const data = JSON.parse(result)
                    console.log(data);
                    this.historyExperiments = [];
                    this.getHistoryExperimentForEachMatrice();
                    this.Dialog = false
                }
				);
        },
        async getHistoryExperiment(matrice){
            const accessToken = localStorage.getItem('accessToken');
            const url = this.$api.getRESTApiUri() + `/get/history-experience`
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    matrice_id : matrice,
                }),
            })
            .then(res => {
                if (res.status === 401) {
                    switchToken().then(() => {
                        // Refaites la requête avec le nouveau token
                        this.getHistoryExperiment(matrice);
                    });
                }
                return res.text();
            })
            .then((result) => {
                const rawData = JSON.parse(result);
                const historyExperiments = rawData[0].history_experiments
                if (historyExperiments) {
                    historyExperiments.forEach((element, index) => {
                        const date_now = Date.now(); // Crée un objet Date représentant l'instant présent
                        const date_fin = element.end_date; // Crée un objet Date pour date_future
                        // Compare date_now à date_future pour déterminer le statut
                        element.index = index;
                        if(date_now < date_fin) {
                            element.statut = 'en cours';
                        }else{
                            element.statut = 'terminé';
                        }
                    });
                    this.historyExperiments.push(...historyExperiments);
                }
            })
        },
        async DeleteExp(){
            console.log(this.itemDelete.matrice)
            console.log(this.itemDelete.index)
            const matrice_id = this.itemDelete.matrice;
            const index = this.itemDelete.index;
            const accessToken = localStorage.getItem('accessToken');
				const url = this.$api.getRESTApiUri() + `/delete/experience`
				return fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        matrice_id : matrice_id,
                        index: index,
                    }),
				})
				.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
							// Refaites la requête avec le nouveau token
							this.DeleteExp();
						});
					}
					return res.text();
				})
				.then((result) => {
                    this.historyExperiments.splice(index, 1);
                    console.log('Experiment deleted successfully');
                    this.DialogDelete = false;
                })
        },
        async AnalyseExp(expérience) {
            // Masquer la courbe actuelle si une est déjà affichée
            if (this.showCurves === true) {
                this.showCurves = false;
                // Utiliser $nextTick pour attendre la mise à jour du DOM
                await this.$nextTick();
            }
            // Mettre à jour l'expérience à analyser
            this.experimentTonAnalyse = expérience;
            // Afficher la nouvelle courbe
            this.showCurves = true;
        },
        async StopExp(expérience){
            const new_end_date = Date.now();
            const matrice_id = expérience.matrice
            expérience.end_date = new_end_date;
            expérience.statut = 'terminé';
            const filteredArray = this.historyExperiments.filter(objet => objet.matrice === matrice_id);
            console.log(filteredArray)
            const accessToken = localStorage.getItem('accessToken');
            const url = this.$api.getRESTApiUri() + `/update/end-date/experience`
            return fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        newExperiments : filteredArray,
                        matrice_id : matrice_id,
                    }),
            })
                .then(res => {
                if (res.status === 401) {
                    switchToken().then(() => {
                        // Refaites la requête avec le nouveau token
                        this.StopExp(expérience);
                    });
                }
                return res.text();
            })
            .then((result) => {
                const data = JSON.parse(result)
                console.log(data);
            });
        },
        async ExportExp(expérience) {
            this.showProgessBar = true;
            this.isError = false;
            this.message = "Export in progress...";

            try {
                const name_exp = expérience.name_of_experiment;
                const tags_to_get_data = expérience.tags;
                const date_debut = expérience.start_date;
                const date_fin = expérience.end_date;
                

                let data;
                try {
                    data = await getDataForExport(tags_to_get_data, date_debut, date_fin, true);
                } catch (error) {
                    this.isError = true;
                    this.message = "Failed to fetch data for export.";
                    console.error(error);
                    return;
                }

                let processed_data;
                let merged_data;
                console.log(data.merged_data)
                try {
                    processed_data = data.processed_data;
                    merged_data = data.merged_data;
                } catch (error) {
                    this.isError = true;
                    this.message = "Failed to process the data.";
                    console.error(error);
                    return;
                }

                try {
                    for (let i = 0; i < processed_data.length; i++) {
                        const csvData = Papa.unparse(processed_data[i]);
                        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
                        saveAs(blob, tags_to_get_data[i] + '_' + name_exp + '.csv');
                    }

                    if (tags_to_get_data.length > 1) {
                        const csvMergeData = Papa.unparse(merged_data);
                        const blobMerge = new Blob([csvMergeData], { type: 'text/csv;charset=utf-8' });
                        saveAs(blobMerge, 'merged data ' + name_exp + '.csv');
                    }

                    this.message = "Export completed successfully!";
                } catch (error) {
                    this.isError = true;
                    this.message = "Failed to generate CSV files.";
                    console.error(error);
                }

            } catch (error) {
                this.isError = true;
                this.message = "An unexpected error occurred.";
                console.error(error);
            } finally {
                 setTimeout(() => {
                    this.showProgessBar = false;
                }, 2000);
            }
        },
        formatDate(date){
            return formatDate(date)
        },
        formatDuree(date_fin, date_debut, statut) {
            // Convertir les chaînes de date en objets moment pour un traitement plus facile
            let debut = moment(date_debut);
            let fin = statut === 'terminé' ? moment(date_fin) : moment(); // Utiliser le moment actuel si la tâche est en cours

            // Calculer la durée en utilisant moment.duration()
            let duree = moment.duration(fin.diff(debut));

            // Extraire les heures et les minutes de la durée
            const heures = Math.floor(duree.asHours());
            const minutes = duree.minutes();

            // Formater et retourner la durée sous forme de chaîne "hh:mm"
            return `${heures.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        },
        rechargeData(){
            console.log('hahaha')
        },
        
        async getHistoryExperimentForEachMatrice(){
            for(let i = 0; i < this.matrice_id.length;  i++){
                await this.getHistoryExperiment(this.matrice_id[i]);
            }
        }
    },
    async mounted(){
        await this.getMatriceAvailable();
        await this.GetTags();
        this.tagsNameAndIds = await getTags();
        await this.getHistoryExperimentForEachMatrice();
    },   
}
</script>

<style >
.mt50{
    margin-top: 50px ;
}
    .button-exp{
        border-radius: 12px;
        padding: 10px;
        border: solid 2px darkgrey;
        display: flex;        /* Active Flexbox */
        align-items: center;  /* Centre verticalement */
    }
    .button-exp:hover{
        background-color: white;
    }
    /* HTML: <div class="loader"></div> */
    .loader {
        width: 50px;
        aspect-ratio: 1;
        display: grid;
        border: 4px solid #0000;
        border-radius: 50%;
        border-right-color: #25b09b;
        animation: l15 1s infinite linear;
    }
    .loader::before,
    .loader::after {    
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
    }
    .loader::after {
    margin: 8px;
    animation-duration: 3s;
    }
    @keyframes l15{ 
    100%{transform: rotate(1turn)}
    }
</style>