<template>
    <div>
        <navbar/> 
        <actionneurs-capteurs-image/>
        <actionneurs-capteurs-presentation class="padding-resp"/>
        <actionneurs-capteurs-liste class="padding-resp mt100"/>
        <actionneurs-capteurs-fonctionnement class="padding-resp mt100"/>
        <bulle-contact/>
        <foot class="mt100"/>
    </div>
</template>

<script>
import foot from '@/components/FooterBis.vue'
import navbar from '@/components/NavBar.vue'
import ActionneursCapteursImage from '../../components/PageActionneursCapteurs/ActionneursCapteursImage.vue';
import ActionneursCapteursListe from '../../components/PageActionneursCapteurs/ActionneursCapteursListe.vue';
import ActionneursCapteursPresentation from '../../components/PageActionneursCapteurs/ActionneursCapteursPresentation.vue';
import ActionneursCapteursFonctionnement from '../../components/PageActionneursCapteurs/ActionneursCapteursFonctionnement.vue';
import BulleContact from '../../components/BulleContact.vue';
export default {
    components:{
      foot,
      navbar,
      ActionneursCapteursImage,
      ActionneursCapteursListe,
      ActionneursCapteursPresentation,
      ActionneursCapteursFonctionnement,
      BulleContact,
    },
    metaInfo: {
        title: 'MiceTracking - Actionneurs et Capteurs Intelligents pour le Bien-Être Animal',
        meta: [
            {
                name: 'description',
                content: 'Découvrez les actionneurs et capteurs MiceTracking pour un suivi précis et interactif des souris de laboratoire. Mesurez ammoniaque, la température, les mouvements, et bien plus pour garantir le bien-être de vos souris de laboratoire en laboratoire.'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, actionneurs, capteurs, bien-être animal, suivi en temps réel, capteur d\'ammoniaque, capteur de température, capteur de mouvements, laboratoire, souris de laboratoire, interaction capteurs/animal'
            }
        ]
    },

    data() {
    return {
      fadeInText1: false,
      fadeInText2: false,
      fadeInText3: false,
      fadeInText4: false,
      fadeInImg1: false,
      fadeInImg2: false,
      fadeInImg3: false,
      fadeInDiv1: false,
      
      // ajoutez d'autres variables de données pour les autres textes (text-2, text-3, text-4) si nécessaire
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  

  methods: {
    
  },
};
</script>