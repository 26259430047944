<template>
	<div class="padding100">
		<navbar/>
		<side-bar/>
		<br>
		<br>
		<tableau-matrice/>
		<br>
		<br>
		<tableaumulti/>
		<br>
		<br>
		<test/>
		<br>
		<br>
		<tableau-amonniac/>
		<br>
		<br>
		<tableau-vibration/>
		<br>
		<br>
		<tableau-sphere/>
		<br>
		<br>
		<button @click="addColumn">Add-Column</button>
	</div>
</template>

<script>
import { switchToken } from '../../functions/test-token';
import navbar from '@/components/NavBarCloud.vue';
import SideBar from '@/components/SideBar'
import Tableaumulti from '../../components/cloud/PageDashboard/Dashboard.vue'
import Test from '../../components/cloud/PageDashboard/DashboardEnv.vue'
import TableauMatrice from '../../components/cloud/PageDashboard/DashboardMatrice.vue'
import TableauAmonniac from '../../components/cloud/PageDashboard/DashboardAmonniac.vue'
import TableauVibration from '../../components/cloud/PageDashboard/DashboardVibration.vue'
import TableauSphere from '../../components/cloud/PageDashboard/DashboardSphere.vue'
import ActivityMixin from '../../functions/ActivityMixin'
	export default {
		mixins: [ActivityMixin],
		components: {
			Test,
			Tableaumulti,
			TableauMatrice,
			TableauAmonniac,
			TableauVibration,
			TableauSphere,
			SideBar,
			navbar,
		},
	data(){
		return{
			accessPage: 0,
		}
	},
	methods:{
		async SelectUser(){
			const user = JSON.parse(localStorage.getItem('user'))
			const accessToken = localStorage.getItem('accessToken');
			const email = user.email
			const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
			const headers = new Headers({
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accessToken}`
			});
			return fetch(url, { headers: headers })
				.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
							// Refaites la requête avec le nouveau token
							this.SelectUser();
						});
					}
					return res.text();
				})
				.then(async (result) => {
					const data = JSON.parse(result);
						console.log(data)
				})
				.catch(error => {
					console.log(error)
				});
		},

		async addColumn() {
			const accessToken = localStorage.getItem('accessToken');
			const url = this.$api.getRESTApiUri() + `/add_column`;
			const headers = new Headers({
				'Content-Type': 'application/json',
				// 'Authorization': `Bearer ${accessToken}`
			});
			return fetch(url, { headers: headers })
				
				.then(async (result) => {
					const data = result
						console.log(data)
				})
				.catch(error => {
					console.log(error)
				});
		}



	}
    }
</script>