<template>
    <div>
        <navbar/>
        <image-plateformes/>
        <plateformes-liste class="mt100"/>
        <plateformes-personnalisables class="mt100"/>
        <footer-vue  class="mt100"/>
        <bulle-contact/>
    </div>
</template>

<script>
import navbar from '@/components/NavBar.vue';
import imagePlateformes from '@/components/PagePlateformes/PlateformesImage.vue'
import PlateformesListe from '@/components/PagePlateformes/PlateformesListe.vue';
import BulleContact from '@/components/BulleContact.vue';
import FooterVue from '@/components/FooterBis.vue';
import PlateformesPersonnalisables from '@/components/PagePlateformes/PlateformesPersonnalisables.vue';
export default {
    components:{
        navbar,
        PlateformesListe,
        imagePlateformes,
        BulleContact,
        PlateformesPersonnalisables,
        FooterVue,
    },
}
</script>