<template>
    <div>
        <navbar/>
        <mission-image/>
        <strombinsoscope class="mt100"/>
        <foot class="mt100"/>
    </div>
</template>

<script>
import foot from '@/components/FooterBis.vue'
import navbar from '@/components/NavBar.vue'
import MissionImage from '../../components/PageMission/MissionImage.vue';
import strombinsoscope from '../../components/PageMission/MissionStrombinoscope.vue'
export default {
    components:{
        foot,
        navbar,
        MissionImage,
        strombinsoscope
    },
    metaInfo: {
        title: 'MiceTracking - Notre Mission : Innover en Recherche Biomédicale et Bien-être Animal',
        meta: [
            {
                name: 'description',
                content: 'Chez MiceTracking, nous innovons en recherche biomédicale avec nos implants de pointe, garantissant le bien-être animal et des mesures précises. Découvrez notre équipe dédiée à améliorer la santé animale.'
            },
            {
                name: 'keywords',
                content: 'MiceTracking, mission, recherche biomédicale, bien-être animal, implants, équipe, santé animale, innovation'
            }
        ]
    },
}
</script>