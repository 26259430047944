<template>
    <div id="chart" v-if="showHeatmap">
        <v-card style="background-color: white;">
            <v-row>
                <v-col cols="12" sm="12" class="row-between">
                    <v-select
                        :items="tagOptions"
                        v-model="selectedTag"
                        label="Select Tag"
                        @change="updateHeatmap"
                    ></v-select>
                    <v-card-title>Heatmap</v-card-title>
                </v-col>
            </v-row>
            <apex-chart type="heatmap" height="500" :options="chartOptions" :series="series"></apex-chart>
        </v-card>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { getHistoryPosition } from '../../../functions/get-data';

export default {
    props: ["experience", "tag_id_name"],
    name: 'accxyz',
    components: {
        'apex-chart': VueApexCharts
    },
    data() {
        return {
            tagData: [],
            series: [],
            showHeatmap: false,
            selectedTag: '',
            tagOptions: [],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'heatmap',
                    toolbar: {
                        autoSelected: 'pan',
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        return val + '%'; // Ajoute le symbole pourcentage
                    }
                },
                yaxis: {
                    labels: {
                        show: false // Désactive les étiquettes sur l'axe y
                    }
                },
                colors: ["#008FFB"],
            },
        }
    },
    async mounted() {
        const { tags, start_date, end_date } = this.experience;
        const filteredTags = tags.filter(tag => tag.startsWith("imp_") || tag.startsWith("pgg1_"));
        const sortedFilteredTags = filteredTags.sort();
        this.tagOptions = sortedFilteredTags;

        const dateDebutISO = this.convertirDateAvecDecalage(new Date(start_date));
        const dateFinISO = this.convertirDateAvecDecalage(new Date(end_date));
        const tagDataArray = await getHistoryPosition(sortedFilteredTags, dateDebutISO, dateFinISO);

        console.log(tagDataArray)
        if (Array.isArray(tagDataArray)) {
            this.tagData = tagDataArray;
        } else {
            console.error("tagData is not an array:", tagDataArray);
        }

        if (sortedFilteredTags.length > 0) {
            this.selectedTag = sortedFilteredTags[0];
            this.updateHeatmap(tagDataArray);
        }
    },
    methods: {
        convertirDateAvecDecalage(date) {
            const decalage = date.getTimezoneOffset() * 60000;
            const dateAvecDecalage = new Date(date.getTime() - decalage);
            return dateAvecDecalage.toISOString();
        },
        findTagData(tag) {
            const tagObject = this.tagData.find(tagObj => Object.keys(tagObj).includes(tag));
            return tagObject ? tagObject[tag] : null;
        },
        updateHeatmap(tagDataArray) {
            const tagPositions = this.findTagData(this.selectedTag);
            if (!tagPositions) return;

            const positionCounts = {};
            let totalCount = 0;

            tagPositions.forEach(({ pos_x_tag, pos_y_tag }) => {
                const key = `${pos_y_tag},${pos_x_tag}`;
                if (!positionCounts[key]) {
                    positionCounts[key] = 0;
                }
                positionCounts[key] += 1;
                totalCount += 1;
            });

            const series = Array.from({ length: 5 }, () => ({ name: '', data: Array.from({ length: 8 }, () => 0) }));
            for (const [key, count] of Object.entries(positionCounts)) {
                const [x, y] = key.split(',').map(Number);
                if (x < 5 && y < 8) {
                    const percentage = (count / totalCount * 100).toFixed(2);
                    series[x].data[y] = parseFloat(percentage);
                }
            }
            this.series = series;
            this.showHeatmap = true;
        }
    }
}
</script>
