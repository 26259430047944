<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="5" xl="4" class="image-container">
        <img src="@/images/matriceActionneursCapteurs.png" alt="">
      </v-col>
      <v-col cols="12" sm="10" lg="5" xl="5" class="detail">
        <h2>{{ $t('pageActionneurs.title') }}</h2>
        <p>{{ $t('pageActionneurs.intro') }}</p>
        <div> 
          <details>
            <summary>{{ $t('pageActionneurs.details.intelligentAutomation.summary') }}</summary>
            <p>{{ $t('pageActionneurs.details.intelligentAutomation.description') }}</p>
          </details>
          <details>
            <summary>{{ $t('pageActionneurs.details.preciseCollection.summary') }}</summary>
            <p>{{ $t('pageActionneurs.details.preciseCollection.description') }}</p>
          </details>
          <details>
            <summary>{{ $t('pageActionneurs.details.flexibleCustomization.summary') }}</summary>
            <p>{{ $t('pageActionneurs.details.flexibleCustomization.description') }}</p>
          </details>
          <details>
            <summary>{{ $t('pageActionneurs.details.animalWellBeing.summary') }}</summary>
            <p>{{ $t('pageActionneurs.details.animalWellBeing.description') }}</p>
          </details>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

.image-container {
  background-color: var(--bleuclair);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  height: 500px; /* Set a fixed height */
}

.image-container img {
  width: 90%;
  object-fit: contain; /* Ensures the image scales within the container */
}

.detail {
  padding-left: 50px;
  overflow: auto; /* Allows the content to scroll if it becomes too large */
}

.detail div {
  background-color: white;
  border-radius: 15px;
}

summary{
  height: 35px;
  padding: 7px 10px;
}

details {
    overflow: hidden;
    padding-left: 10px;
    font-size: 18px;
  }
details[open] p {
    animation: slideDown 0.3s ease-in-out;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

@media (max-width: 960px) {

.detail{
  margin-top: 50px;
}
.detail p{
  margin-top: 15px;
}

.detail div{
  margin-top: 35px;
}

}
</style>

<script>
  export default {
		name: 'App',
		components: {
		},
	};
</script>