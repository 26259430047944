<template>
   <div style="margin-top: 15px;">
        <v-col>
            <div class="box-curves">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Température (°C)</v-card-title>
						<v-card-title ><button class="button-exp" @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</button></v-card-title>
					</v-col>
				</v-row>
        <line-chart  :key="chartKey" :options="TemperatureChartOptions" :chartColors="températureEnvChartColors" :chartColorsImplant="températureImplantChartColors" :tag_id_name="tag_id_name" :tag_id="tag_id"  :pause="isPaused" />
        <br>
			</div>
    </v-col>
  </div>
</template>

<script>
import { getDataLastId } from '../../../functions/get-data'
import LineChart from "./LineChartMultipleTemp.js";

export default {
	props:["tag_id", "tag_id_name"],
    components: {
			LineChart,
		},
    data() {
			return {	
				chartKey: 0,
				id_memo : [],	
				températureImplantChartColors: [
					{
						borderColor: 'rgb(0, 188, 212)',
						backgroundColor: 'rgba(0, 188, 212, 0.2)'
					},
					{
						borderColor: 'rgb(255, 153, 102)',
						backgroundColor: 'rgba(255, 153, 102, 0.2)'
					},
					{
						borderColor: 'rgb(150, 123, 200)',
						backgroundColor: 'rgba(150, 123, 200, 0.2)'
					},
					{
						borderColor: 'rgb(157, 180, 63)',
						backgroundColor: 'rgba(57, 180, 63, 0.2)'
					},
				],
				températureEnvChartColors: [
					{
						borderColor: 'rgb(31, 61, 219)',
						backgroundColor: 'rgba(31, 61, 219, 0.2)'
					},
					{
						borderColor: 'rgb(151, 42, 225)',
						backgroundColor: 'rgba(151, 42, 225, 0.2)'
					},
				],
				isPaused: false,
                TemperatureChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 30,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 1000,
								delay: 2000,
								onRefresh: (chart) => {
								for (let i = 0; i < this.tag_id.length; i++) {
                                    this.getTemp(i).then((newData) => {
                                        let g = JSON.parse(JSON.stringify(newData));
                                        chart.data.datasets[i].data.push(...g);
                                    }).catch((e) => {
                                        console.log("[FETCH] Error");
                                    });
                                }
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
                
			};
		},
		methods: {
            async getTemp(i) {
				const newData = []; // Déclarer le tableau à l'extérieur de la fonction
				try {
					const data = await getDataLastId(this.tag_id[i])
					console.log(data)
					if(data[0].id <= this.id_memo[i]){
						newData.push({
							x: Date.now(), 
						});
					}else{
						let temp = data[0].temp_tag/100;
						console.log(data)
						newData.push({
							x: Date.now(), 
							y: temp.toFixed(2), 
						});
					}
					this.id_memo[i]  = data[0].id
				} catch (error) {
					console.error(error.message);
					throw new Error('Erreur lors de l\'appel à l\'API'); // Lever une erreur générique en cas d'erreur
				}
				return newData;
			},
			forceChartRerender() {
				this.chartKey += 1; // Incrémenter la clé pour forcer le re-rendu
			},
			togglePause() {
				this.isPaused = !this.isPaused;
			},
			
		}
	};
			
</script>