import { render, staticRenderFns } from "./MissionImage.vue?vue&type=template&id=897c9688&scoped=true&"
var script = {}
import style0 from "./MissionImage.vue?vue&type=style&index=0&id=897c9688&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "897c9688",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol})
