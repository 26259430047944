<template>
    <div id="wrapper">
        <div id="chart-line2" v-if="showChart">
            <v-card class="chart-card">
                <v-row>
                    <v-col cols="12" sm="12" class="row-between">
                        <div class="button-group" style="width: 15%;" >
                            <button class="button-exp" @click="displayTypeData('min')">Min.</button>
                            <button class="button-exp" @click="displayTypeData('moy')">Moy.</button>
                            <button class="button-exp" @click="displayTypeData('max')">Max.</button>
                        </div>
                        <v-card-title style="width: 50%;">Courbes historique : {{ experience.name_of_experiment }}</v-card-title>
                        <div ></div>
                    </v-col>
                </v-row>
                <apexchart type="line" ref="lineChart1" height="700" :options="chartOptions" :series="datasets"></apexchart>
            </v-card>
    </div>
    <v-dialog v-model="showCustomExport" persistent max-width="600px">
    <v-card>
    <v-card-title>
        <span class="headline">Choix de la période d'échantillonage</span>
    </v-card-title>
    <v-card-text>
        <v-row>
            <v-col cols="12" sm="12">
                <v-select v-model="tags_selected" :items="experience.tags"  multiple label="Select Tags" chips persistent-hint >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 1">
                        <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index < 1" class="text-grey text-caption align-self-center" >
                        (+{{ tags_selected.length - 1 }} others)
                        </span>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="4">
                <v-select v-model="selectedUnit" :items="units" label="Unité" chips persistent-hint item-text="value" item-value="units" ></v-select>
            </v-col>
            <v-col cols="12" sm="8">
                <v-slider v-model="period" :max="maxPeriod" :min="5" :step="5" thumb-label="always" style="margin-top: 40px;"></v-slider>
            </v-col>
        </v-row>
        <span>Periode: {{ period }} {{ selectedUnit }}</span>
        
        <v-row>
            <v-range-slider
                v-model="range"
                :min="minDate"
                :max="maxDate"
                :step="600000"
            ></v-range-slider>
        </v-row>
        <div style="width: 100%;" class="row-between">
            <p>{{ formatDate(range[0]) }}</p>
            <p>{{ formatDuree(range[0], range[1]) }}</p>
            <p>{{ formatDate(range[1]) }}</p>
        </div>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="showCustomExport = false">Close</v-btn>
        <v-btn color="blue darken-1" text @click="DisplayData('moy')">Export</v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
<v-dialog v-model="showProgessBar" max-width="450" persistent style="background-color: rgba(0, 0, 0, 0.5);">
  <v-progress-linear
    :color="isError ? 'red' : 'green'"
    indeterminate
    style="height: 8px;"
  ></v-progress-linear>
  <div :style="{ width: '100%', textAlign: 'center', marginTop: '15px', color: isError ? 'red' : 'white' }">
    <p>{{ message }}</p>
  </div>
</v-dialog>

    </div>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import {  getDataForExport } from '../../../functions/get-data';
import { groupDataByTimeInterval, calculateSummaries  } from '../../../functions/traitement'
import { convertirDateAvecDecalage  } from '../../../functions/convert-date';
import moment from 'moment';

export default{
    components: {
        apexchart: VueApexCharts
    },
    props:["experience", "tag_id_name"],

    data(){
        return{
            showProgessBar : false,
            message: 'hey',
            isError: false,
            showChart : false,
            date_debut: new Date(this.experience.start_date),
            date_fin: new Date(this.experience.end_date),
            range: [
                new Date(this.experience.start_date).getTime(),
                new Date(this.experience.end_date).getTime(),
            ],
            showCustomExport : true,
            units: [
                { name: 'Seconds', value: 'Secondes' },
                { name: 'Minutes', value: 'Minutes' },
                { name: 'heures', value: 'Heures' },
            ],
            selectedUnit: 'seconds',
            tags_selected:[],
            period: 1,
            maxPeriod: 60,
            tagCounters: {},
            allMin : [],
            allMax : [],
            allMoy : [],
            etatCageMapping: {
                'Faible': 2,
                'Moyen': 1,
                'Forte': 0,
            },
            etatMapping: {
                'Repos': 2,
                'Active': 1,
                'Excitée': 0,
            },
            colorMapping: {
                'imp': { 
                    'temperature': ['#00BCD4', '#FF9966', '#967BD8', '#39B43F'],
                    'acc_x': ['#B03B23', '#F45131', '#6B2415', '#8E5448'],
                    'acc_y': ['#4B45F6', '#4E47FF', '#3632B3', '#2C2891'],
                    'acc_z': ['#2C2891', '#3D9128', '#6CFF47', '#47703D'],
                },
                'env': {
                    'temperature': ['#1F3DDB', '#972AE1', '#00BCD4'],
                    'humidite': ['#02C39A', '#176353', '#62D932'],
                    'luminosite': ['#FFD166', '#D99232', '#D95832']
                },
                'vib': {
                    'acc_x': ['#B03B23', '#F45131', '#6B2415', '#8E5448'],
                    'acc_y': ['#4B45F6', '#4E47FF', '#3632B3', '#2C2891'],
                    'acc_z': ['#2C2891', '#3D9128', '#6CFF47', '#47703D'],
                },
                'vib2': {
                    'acc_x': ['#B03B23', '#F45131', '#6B2415', '#8E5448'],
                    'acc_y': ['#4B45F6', '#4E47FF', '#3632B3', '#2C2891'],
                    'acc_z': ['#2C2891', '#3D9128', '#6CFF47', '#47703D'],
                },
                'amq': {
                    'ammoniac' : ['#FFD166', '#D99232', '#D95832'],
                }
            },

            datasets: [],
            chartOptions: {
                chart: {
                    animations: {
                        enabled: false
                    },
                    id: 'chart2',
                    type: 'line',
                    height: 230,
                    toolbar: {
                        autoSelected: 'pan',
                        show: true,
                        export: {
                        csv: {
                            dateFormatter(timestamp) {
                                // Utilisez toLocaleString avec des options spécifiques
                                return new Date(timestamp).toLocaleString('fr-FR', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                });
                            }
                        },
                        svg: {
                            filename: undefined,
                        },
                        png: {
                            filename: undefined,
                        }
                    },
                    },
                    
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'straight'
                },
                markers: {
                    size: 0
                },
                yaxis: [
                ],
                xaxis: {
                    type: 'datetime',
                    labels: {
                    show: true,
                    formatter: function(val, opts) {
                        return moment(val).format('HH:MM:SS');
                    }
                    },
                    tooltip: {
                        enabled: false,
                        formatter: function(val, opts) {
                            return moment(val).format('LL, LT');
                        }
                    },
                },
                tooltip: {
                    x: {
                        formatter: function(val, opts) {
                            return moment(val).format('LL, LT');
                        } // Format pour la partie date dans l'infobulle générale
                    },
                    y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                        const seriesName = w.config.series[seriesIndex].name;
                        // Inspecter le nom de la série pour déterminer le formatage
                        if (seriesName.startsWith('Lum_')) {
                            return `${value.toFixed(0)} Lux`; // Format pour la luminosité
                        } else if (seriesName.startsWith('Temp_')) {
                            return `${(value/100).toFixed(2)} °C`; // Format pour la température
                        }else if (seriesName.startsWith('Act_')) {
                            const etatMap = {0: 'Excitée', 1: 'Active', 2: 'Repos'};
                            return etatMap[value] || value; // Format pour la température
                        }else if (seriesName.startsWith('Hum_')) {
                            return `${(value).toFixed(0)} %`; // Format pour la température
                        } else {
                            return value; // Format par défaut
                        }
                        }
                    }
                },
                grid: {
                    show: true
                }
            },
            }
        },
        
        computed: {
            minDate() {
                return new Date(this.experience.start_date).getTime();
            },
            maxDate() {
                return new Date(this.experience.end_date).getTime();
            },
        },
        mounted(){
        },
        methods : {
              async DisplayData(type) {
                this.showProgessBar = true;
                this.showCustomExport = false;
                this.message = 'Collecte de vos données...';
                this.isError = false;

                try {
                const periode = this.determinePeriode(this.selectedUnit, this.period); // Calcul de la période
                const responseFetchData = await this.getAllDataHistorical(periode); // Fetch et traitement des données
                if (!responseFetchData) {
                    this.showErrorMessage('Erreur lors de la collecte des données. Veuillez réessayer.');
                    return false;
                }
                this.showChart = true;
                await this.displayTypeData(type); // Affichage des données
                this.showSuccessMessage('Succès, amusez-vous bien !');
                return true;
                } catch (error) {
                console.error(error);
                this.showErrorMessage('Une erreur s\'est produite lors du traitement des données. Veuillez réessayer.');
                return false;
                }
            },
            async displayTypeData(type) {
                try {
                    const transformedDataArray = await this.displaySerie(type); // Récupération des données prêtes pour affichage
                    const axisConfig = this.generateConfig(transformedDataArray); // Création de la config des axes y en fonction des séries
                    await this.updateChart(transformedDataArray, axisConfig);
                } catch (error) {
                    console.error(error);
                    throw new Error('Erreur lors de l\'affichage des données.');
                }
            },
            async getAllDataHistorical(periode) {
                try {
                const tags = this.tags_selected;
                const dateDebutISO = await convertirDateAvecDecalage(new Date(this.range[0]));
                const dateFinISO = await convertirDateAvecDecalage(new Date(this.range[1]));
                const data = await getDataForExport(tags, dateDebutISO, dateFinISO, false);

                if (data == false) {
                    this.showErrorMessage('Erreur lors de la collecte des données, veuillez réessayer.');
                    return false;
                }

                const processed_data = data.processed_data
                console.log(processed_data)
                this.message = 'Préparation de votre graphique...';
                
                for (let i = 0; i < processed_data.length; i++) {
                    const groupedData = await groupDataByTimeInterval(processed_data[i], periode);
                    const summariesData = await calculateSummaries(groupedData);
                    const { minSeries, maxSeries, moySeries } = this.processSummaryDataIntoCategories(summariesData);
                    this.allMin.push(...minSeries);
                    this.allMax.push(...maxSeries);
                    this.allMoy.push(...moySeries);
                }

                return true;
                } catch (error) {
                console.error(error);
                this.showErrorMessage('Erreur lors du traitement des données. Veuillez réessayer.');
                return false;
                }
            },
            async displaySerie(typeSerie){
                this.tagCounters = {}
                let transformedDataArray = []
                if(typeSerie == 'min'){
                    transformedDataArray = this.assignColorsToSeries(this.allMin);
                }else if(typeSerie == 'max'){
                    transformedDataArray = this.assignColorsToSeries(this.allMax);
                }else if(typeSerie == 'moy'){
                    transformedDataArray = this.assignColorsToSeries(this.allMoy);
                }
                return transformedDataArray
            },
            generateConfig(transformedDataArray){
                const tempSeriesNames = [];
                const activitySeriesNames = [];
                const LuminositeSeriesNames = [];
                const humiditySeriesNames = [];
                const amonniacSeriesNames =[]; 
                // Accumuler les noms pour les configurations des axes
                for (let i = 0; i < transformedDataArray.length; i++) {
                    if (transformedDataArray[i].yAxisName === 'Temperature') {
                        tempSeriesNames.push(transformedDataArray[i].name);
                    }else if (transformedDataArray[i].yAxisName === 'Acc') {
                        activitySeriesNames.push(transformedDataArray[i].name);
                    }else if (transformedDataArray[i].yAxisName === 'Humidite') {
                        humiditySeriesNames.push(transformedDataArray[i].name);
                    }else if (transformedDataArray[i].yAxisName === 'Luminosite') {
                        LuminositeSeriesNames.push(transformedDataArray[i].name);
                    }else if (transformedDataArray[i].yAxisName === 'Ammoniac') {
                        amonniacSeriesNames.push(transformedDataArray[i].name);
                    }
                }
                return this.createYAxisConfigs(tempSeriesNames, activitySeriesNames, humiditySeriesNames, LuminositeSeriesNames, amonniacSeriesNames);
            },
            async updateChart(transformedDataArray, axisConfig) {
                this.$refs.lineChart1.updateOptions({
                    yaxis: axisConfig,
                    series: transformedDataArray,
                }, true, false, true);
            },
            processSummaryDataIntoCategories(summariesData) {
                const minSeries = [];
                const maxSeries = [];
                const moySeries = [];

                summariesData.forEach(item => {
                    Object.keys(item).forEach(key => {
                        const keyParts = key.split('_');
                        const type = keyParts[0]; // "MIN", "MOY", or "MAX"
                        const dataType = keyParts[2];
                        const name = `${type}_${keyParts.slice(1).join('_')}`; // Construct the name

                        let targetArray;
                        if ((type === 'MIN' && (dataType === 'Temperature' || dataType === 'Acc' || dataType === 'Luminosite' || dataType == 'Humidite'  || dataType == 'Ammoniac'))) {
                            targetArray = minSeries;
                        } else if ((type === 'MAX' && (dataType === 'Temperature' || dataType === 'Acc' || dataType === 'Luminosite' || dataType == 'Humidite' || dataType == 'Ammoniac'))) {
                            targetArray = maxSeries;
                        } else if ((type === 'MOY' && (dataType === 'Temperature' || dataType === 'Acc' || dataType === 'Luminosite' || dataType == 'Humidite' || dataType == 'Ammoniac'))) {
                            targetArray = moySeries;
                        }

                        if (targetArray) {
                            let foundSeries = targetArray.find(s => s.name === name);
                            if (!foundSeries) {
                                foundSeries = {
                                    name: name,
                                    data: [],
                                };
                                targetArray.push(foundSeries);
                            }
                            foundSeries.data.push([new Date(item.date).getTime(), parseFloat(item[key])]);
                        } else {
                            // console.error("Undefined targetArray for type:", type, "dataType:", dataType);
                        }
                    });
                });
                return { minSeries, maxSeries, moySeries };
            },
            assignColorsToSeries(seriesArray) {
                seriesArray.forEach((series, index) => {
                    const { name } = series;
                    const parts = name.split('_');
                    const prefix = parts[0]; // "MOY", "MIN", or "MAX"
                    const tagType = parts[1].toLowerCase(); // "IMP", "ENV", etc.
                    const dataType = parts[2].toLowerCase(); // "Temperature", "Acc", etc.
                    
                    let axisSuffix = parts.length > 3 ? parts[3].toLowerCase() : ''; // "X", "Y", "Z"

                    let yAxisName = parts[2];
                    let colorType = dataType;
                    if (dataType === 'acc' && axisSuffix) {
                        colorType += `_${axisSuffix}`; // "acc_x", "acc_y", "acc_z"
                    }

                    // Construct a unique identifier for each tag and type combination
                    const tagTypeColorKey = `${tagType}_${colorType}`;

                    // Initialize the counter for this combination if not already done
                    if (!this.tagCounters[tagTypeColorKey]) {
                        this.tagCounters[tagTypeColorKey] = 0;
                    }
                    // Ensure tag type and color type are defined in colorMapping
                    if (this.colorMapping[tagType] && this.colorMapping[tagType][colorType]) {
                        const colorIndex = this.tagCounters[tagTypeColorKey]++ % this.colorMapping[tagType][colorType].length;
                        const color = this.colorMapping[tagType][colorType][colorIndex];

                        // Update the series object
                        series.yAxisName = yAxisName;
                        series.color = color;
                    } else {
                        console.error(`Color mapping for ${tagType} ${colorType} is not defined.`);
                        series.yAxisName = yAxisName;
                        series.color = '#000000'; // Default color if none is defined
                    }
                });
                return seriesArray;
            },
            createYAxisConfigs(tempSeriesNames, activitySeriesNames, LuminositeSeriesNames, humiditySeriesNames, amonniacSeriesNames) {
                    const yAxisConfig = [];
                    if(tempSeriesNames.length){
                        yAxisConfig.push({
                            seriesName: [tempSeriesNames],
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: 'rgb(0, 188, 212)'
                            },
                            labels: {
                                style: {
                                    colors: 'rgb(0, 188, 212)'
                                },
                                formatter: val => (val).toFixed(2) + '°C'
                            },
                            
                            title: {
                                text: 'Température (°C)',
                                style: {
                                    color: 'rgb(0, 188, 212)'
                                }
                            },
                        },)
                    }
                    if(activitySeriesNames.length){
                        yAxisConfig.push({
                            seriesName: activitySeriesNames,
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: '#D95832'
                            },
                            labels: {
                                style: {
                                    colors: '#D95832'
                                },
                                
                            },
                            title: {
                                text: 'Activité',
                                style: {
                                    color: '#D95832'
                                }
                            },
                        })
                    }
                    if(LuminositeSeriesNames.length){
                        yAxisConfig.push({
                            seriesName: LuminositeSeriesNames,
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: '#02C39A'
                            },
                            labels: {
                                style: {
                                    colors: '#02C39A'
                                },
                                formatter: val => val.toFixed(2) + ' %'
                            },
                            title: {
                                text: 'Humidité',
                                style: {
                                    color: '#02C39A'
                                }
                            },
                        })
                    }
                    if(humiditySeriesNames.length){
                        yAxisConfig.push({
                            seriesName: humiditySeriesNames,
                            opposite: true,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: '#FFD166'
                            },
                            labels: {
                                style: {
                                    colors: '#FFD166'
                                },
                                formatter: val => val.toFixed(0) + ' Lux'
                            },
                            title: {
                                text: 'Luminosité',
                                style: {
                                    color: '#FFD166'
                                }
                            },
                        })
                    }
                    if(amonniacSeriesNames.length){
                        yAxisConfig.push({
                            seriesName: [amonniacSeriesNames],
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: '#FFD166'
                            },
                            labels: {
                                style: {
                                    colors: '#FFD166'
                                },
                                formatter: val => (val).toFixed(2) + 'ppm'
                            },
                            
                            title: {
                                text: 'Amonniaque (ppm)',
                                style: {
                                    color: '#FFD166'
                                }
                            },
                        },)
                    }
                return yAxisConfig;
            },
            mapActiviteToNumeric(ecart_type, etatCageMapping) {
                return ecart_type.map(item => {
                    return {
                    received_on: item.received_on,
                    activité: etatCageMapping[item.activité] !== undefined ? etatCageMapping[item.activité] : null
                    };
                });
            },
            determinePeriode(unit, durée){
                let multiplicateurPeriode = 1;
                switch (unit){
                    case 'Secondes' : 
                        multiplicateurPeriode = 1;
                        break;
                    case 'Minutes' : 
                        multiplicateurPeriode = 60;
                        break;
                    case 'Heures' : 
                        multiplicateurPeriode = 3600;
                        break;
                }
                return durée*multiplicateurPeriode
            },
            showErrorMessage(message) {
                this.message = message;
                this.isError = true;
                setTimeout(() => {
                this.showProgessBar = false;
                }, 5000); // Le message d'erreur sera affiché pendant 5 secondes
            },
            showSuccessMessage(message) {
                this.message = message;
                this.isError = false;
                setTimeout(() => {
                this.showProgessBar = false;
                }, 2000); // Le message de succès sera affiché pendant 2 secondes
            },
            formatDate(timestamp) {
                return moment(timestamp).format('LL, LT');
            },
            formatDuree(timestamp1, timestamp2){
                const timestamp = timestamp2 - timestamp1
                return new moment.utc(timestamp).format('HH:mm')
            }
        },
        
}
</script>

<style>
.chart-card {
    background-color: white;
    border-radius: 20px;
    padding-top: 20px;
    margin-bottom: 50px;
    margin-top: 50px;
}
.button-group button {
    margin-left: 20px;
}

.button-group {
    display: flex; 
}
</style>