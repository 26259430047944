<template>
    <!-- Ajout de la classe `account-container` à la div parente -->
    <div class="account-container">
        <navbar/>
        <side-bar/>
        <h1 class="title-account">Mes Tags</h1>
        <!-- Ajout de la classe `data-table-container` autour du tableau -->
        <div class="data-table-container">
            <table class="data-table">
                <thead>
                    <tr>
                        <th>Tag ID</th>
                        <th>Nom</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tag, index) in tag_id" :key="index">
                        <td>{{ tag }}</td>
                        <td>{{ tag_name[index] }}</td>
                        <td>
                            <!-- Réduis la taille des boutons en ajustant les classes -->
                            <div class="row-around">
                                <button class="small-button" @click="openModal(index)">Renommer</button>
                                <button class="small-button-reset" @click="resetNameImplant(index, 'implant')">Reset</button>
                            </div>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
        <h1 class="title-account">Mes Matrices</h1>
         <div class="data-table-container-matrice">
            <table class="data-table">
                <thead>
                    <tr>
                        <th>Matrice ID</th>
                        <th>Nom</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(matrice, index) in matrice_id" :key="index">
                        <td>{{ matrice }}</td>
                        <td>{{ matrice_name[index] }}</td>
                        <td>
                            <!-- Réduis la taille des boutons en ajustant les classes -->
                             <div class="row-around">
                                <button class="small-button" @click="openModal2(index)">Renommer</button>
                                <button class="small-button-reset" @click="resetNameImplant(index, 'matrice')">Reset</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h1 class="title-account">Mes capteurs ammoniac</h1>
         <div class="data-table-container-matrice">
            <table class="data-table">
                <thead>
                    <tr>
                        <th>Capteur ID</th>
                        <th>Nom</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(capteur, index) in capteur_amonniaque" :key="index">
                        <td>{{ capteur }}</td>
                        <td>{{ capteur_amonniaque_name[index] }}</td>
                        <td>
                            <!-- Réduis la taille des boutons en ajustant les classes -->
                             <div class="row-around">
                                <button class="small-button" @click="openModal3(index)">Renommer</button>
                                <button class="small-button-reset" @click="resetNameImplant(index, 'ammoniac')">Reset</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h1 class="title-account">Mes capteurs vibration</h1>
         <div class="data-table-container-matrice">
            <table class="data-table">
                <thead>
                    <tr>
                        <th>Capteur ID</th>
                        <th>Nom</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(capteur, index) in capteur_vibration" :key="index">
                        <td>{{ capteur }}</td>
                        <td>{{ capteur_vibration_name[index] }}</td>
                        <td>
                            <!-- Réduis la taille des boutons en ajustant les classes -->
                             <div class="row-around">
                                <button class="small-button" @click="openModal4(index)">Renommer</button>
                                <button class="small-button-reset" @click="resetNameImplant(index, 'vibration')">Reset</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h1 class="title-account">Mes capteurs environnement</h1>
         <div class="data-table-container-matrice">
            <table class="data-table">
                <thead>
                    <tr>
                        <th>Capteur ID</th>
                        <th>Nom</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(capteur, index) in capteur_environnement" :key="index">
                        <td>{{ capteur }}</td>
                        <td>{{ capteur_environnement_name[index] }}</td>
                        <td>
                            <!-- Réduis la taille des boutons en ajustant les classes -->
                             <div class="row-around">
                                <button class="small-button" @click="openModal5(index)">Renommer</button>
                                <button class="small-button-reset" @click="resetNameImplant(index, 'environnement')">Reset</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h1 class="title-account">Mes capteurs sphères de jeu</h1>
         <div class="data-table-container-matrice">
            <table class="data-table">
                <thead>
                    <tr>
                        <th>Capteur ID</th>
                        <th>Nom</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(capteur, index) in capteur_sphere" :key="index">
                        <td>{{ capteur }}</td>
                        <td>{{ capteur_sphere_name[index] }}</td>
                        <td>
                            <!-- Réduis la taille des boutons en ajustant les classes -->
                             <div class="row-around">
                                <button class="small-button" @click="openModal6(index)">Renommer</button>
                                <button class="small-button-reset" @click="resetNameImplant(index, 'sphere')">Reset</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <span class="close-icon" @click="closeModal">✖</span>
                <h2>Renommer le tag</h2>
                <input class="input-name" v-model="newTagName" placeholder="Nouveau nom">
                <div>
                    <button class="btn-modal" @click="renameTag">Valider</button>
                </div>
            </div>
        </div>

        <div v-if="showModal2" class="modal">
            <div class="modal-content">
                <span class="close-icon" @click="closeModal">✖</span>
                <h2>Renommer la matrice</h2>
                <input class="input-name" v-model="newMatriceName" placeholder="Nouveau nom">
                <div>
                    <button class="btn-modal" @click="renameMatrice">Valider</button>
                </div>
            </div>
        </div>
        <div v-if="showModal3" class="modal">
            <div class="modal-content">
                <span class="close-icon" @click="closeModal">✖</span>
                <h2>Renommer le capteur amonniac</h2>
                <input class="input-name" v-model="newAmonniacName" placeholder="Nouveau nom">
                <div>
                    <button class="btn-modal" @click="renameAmonniac">Valider</button>
                </div>
            </div>
        </div>
        <div v-if="showModal4" class="modal">
            <div class="modal-content">
                <span class="close-icon" @click="closeModal">✖</span>
                <h2>Renommer le capteur vibration</h2>
                <input class="input-name" v-model="newVibrationName" placeholder="Nouveau nom">
                <div>
                    <button class="btn-modal" @click="renameVibration">Valider</button>
                </div>
            </div>
        </div>
        <div v-if="showModal5" class="modal">
            <div class="modal-content">
                <span class="close-icon" @click="closeModal">✖</span>
                <h2>Renommer le capteur environnement</h2>
                <input class="input-name" v-model="newEnvironnementName" placeholder="Nouveau nom">
                <div>
                    <button class="btn-modal" @click="renameEnvironnement">Valider</button>
                </div>
            </div>
        </div>
        <div v-if="showModal6" class="modal">
            <div class="modal-content">
                <span class="close-icon" @click="closeModal">✖</span>
                <h2>Renommer le capteur sphère</h2>
                <input class="input-name" v-model="newSphereName" placeholder="Nouveau nom">
                <div>
                    <button class="btn-modal" @click="renameSphere">Valider</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { switchToken } from '../../functions/test-token';
import navbar from '@/components/NavBarCloud.vue';
import SideBar from "@/components/SideBar.vue"

export default {
    components: { SideBar, navbar },
  data(){
    return {
        tag_id: [],
        tag_name: [],
        matrice_id: [],
        matrice_name : [],
        capteur_amonniaque : [],
        capteur_amonniaque_name : [],
        capteur_vibration : [],
        capteur_vibration_name :[],
        capteur_sphere : [],
        capteur_sphere_name : [],
        capteur_environnement : [],
        capteur_environnement_name  : [],
        espace: [],
        showModal: false,
        showModal2: false,
        showModal3: false,
        showModal4: false,
        showModal5: false,
        showModal6: false,
        currentTagIndex: null,
        newTagName: '',
        newMatriceName: '',
        newAmonniacName: '',
        newVibrationName: '',
        newSphereName: '',
        newEnvironnementName: '',
        email: ''
    }
  },
 mounted(){
    this.getTags();
 },
  methods: {
        openModal(index) {
            this.currentTagIndex = index;
            this.newTagName = this.tag_name[index];
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.showModal2 = false;
            this.showModal3 = false;
            this.showModal4 = false;
            this.showModal5 = false;
            this.showModal6 = false;
        },
        openModal2(index) {
            this.currentTagIndex = index;
            this.newMatriceName = this.matrice_name[index];
            this.showModal2 = true;
        },
        openModal3(index) {
            this.currentTagIndex = index;
            this.newAmonniacName = this.capteur_amonniaque_name[index];
            this.showModal3 = true;
        },
        openModal4(index) {
            this.currentTagIndex = index;
            this.newVibrationName = this.capteur_vibration_name[index];
            this.showModal4 = true;
        },
        openModal5(index) {
            this.currentTagIndex = index;
            this.newenvironnementName = this.capteur_environnement_name[index];
            this.showModal5 = true;
        },
        openModal6(index) {
            this.currentTagIndex = index;
            this.newSphereName = this.capteur_sphere_name[index];
            this.showModal6 = true;
        },
        closeModal2() {
            this.showModal2 = false;
        },
        closeModal3() {
            this.showModal3 = false;
        },
        closeModal4() {
            this.showModal4 = false;
        },
        closeModal5() {
            this.showModal5 = false;
        },
        closeModal6() {
            this.showModal6 = false;
        },
        renameTag() {
            console.log(this.email)
            if (this.currentTagIndex !== null) {
                this.tag_name.splice(this.currentTagIndex, 1, this.newTagName);
                const url = this.$api.getRESTApiUri() + `/user/new-tag-name`;
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                                email: this.email,
                                tag_id: this.tag_name,
                        }),
                    })
                    // Converting to JSON
                    .then(response => response.json())
                    // Displaying results to console
                    .then(json => console.log(json));
                    this.closeModal();
            }
        },
        renameMatrice() {
            if (this.currentTagIndex !== null) {
                this.matrice_name.splice(this.currentTagIndex, 1, this.newMatriceName);
                const url = this.$api.getRESTApiUri() + `/user/new-matrice-name`;
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                                email: this.email,
                                matrice_id: this.matrice_name,
                        }),
                    })
                    // Converting to JSON
                    .then(response => response.json())
                    // Displaying results to console
                    .then(json => console.log(json));
                    this.closeModal();
            }
        },
        renameAmonniac() {
            if (this.currentTagIndex !== null) {
                this.capteur_amonniaque_name.splice(this.currentTagIndex, 1, this.newAmonniacName);
                const url = this.$api.getRESTApiUri() + `/user/new-amonniac-name`;
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_amonniaque_name,
                        }),
                    })
                    // Converting to JSON
                    .then(response => response.json())
                    // Displaying results to console
                    .then(json => console.log(json));
                    this.closeModal();
            }
        },
        renameVibration() {
            if (this.currentTagIndex !== null) {
                this.capteur_vibration_name.splice(this.currentTagIndex, 1, this.newVibrationName);
                const url = this.$api.getRESTApiUri() + `/user/new-vibration-name`;
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_vibration_name,
                        }),
                    })
                    // Converting to JSON
                    .then(response => response.json())
                    // Displaying results to console
                    .then(json => console.log(json));
                    this.closeModal();
            }
        },
        renameEnvironnement() {
            if (this.currentTagIndex !== null) {
                this.capteur_environnement_name.splice(this.currentTagIndex, 1, this.newEnvironnementName);
                const url = this.$api.getRESTApiUri() + `/user/new-environnement-name`;
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_environnement_name,
                        }),
                    })
                    // Converting to JSON
                    .then(response => response.json())
                    // Displaying results to console
                    .then(json => console.log(json));
                    this.closeModal();
            }
        },
        renameSphere() {
            if (this.currentTagIndex !== null) {
                this.capteur_sphere_name.splice(this.currentTagIndex, 1, this.newSphereName);
                const url = this.$api.getRESTApiUri() + `/user/new-sphere-name`;
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_sphere_name,
                        }),
                    })
                    // Converting to JSON
                    .then(response => response.json())
                    // Displaying results to console
                    .then(json => console.log(json));
                    this.closeModal();
            }
        },
        resetNameImplant(index, type_tag){
                let url;
                let body;
                switch (type_tag){
                    case 'implant': 
                        this.tag_name.splice(index, 1, 'noname');
                        url = this.$api.getRESTApiUri() + `/user/new-tag-name`;
                        body =  JSON.stringify({
                                email: this.email,
                                tag_id: this.tag_name,
                        })
                    break;
                    case 'matrice': 
                        this.matrice_name.splice(index, 1, 'noname');
                        url = this.$api.getRESTApiUri() + `/user/new-matrice-name`;
                        body = JSON.stringify({
                                email: this.email,
                                matrice_id: this.matrice_name,
                        });
                    break;
                    case 'ammoniac':
                        this.capteur_amonniaque_name.splice(index, 1, 'noname');
                        url = this.$api.getRESTApiUri() + `/user/new-amonniac-name`;
                        body = JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_amonniaque_name,
                        });
                    break;
                    case 'vibration': 
                        this.capteur_vibration_name.splice(index, 1, 'noname');
                        url = this.$api.getRESTApiUri() + `/user/new-vibration-name`;
                        body = JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_vibration_name,
                        });
                    break;
                    case 'environnement':
                        this.capteur_environnement_name.splice(index, 1, 'noname'); 
                        url = this.$api.getRESTApiUri() + `/user/new-environnement-name`;
                        body = JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_environnement_name,
                        });
                    break;
                    case 'sphere': 
                        this.capteur_sphere_name.splice(index, 1, 'noname');
                        url = this.$api.getRESTApiUri() + `/user/new-sphere-name`;
                        body = JSON.stringify({
                                email: this.email,
                                capteur_id: this.capteur_sphere_name,
                        });
                    break;
                }
                console.log(url)
                    fetch(url, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: body
                    })
                    // Converting to JSON
                    .then(response => response.json())
                    // Displaying results to console
                    .then(json => console.log(json));
        },

        async getTags(){
           const user = JSON.parse(localStorage.getItem('user'))
            const accessToken = localStorage.getItem('accessToken');
            const email = user.email
            this.email = email;
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            });
            return fetch(url, { headers: headers })
                .then(res => {
                if (res.status === 401) {
                    switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                    this.getTags();
                    });
                }
                return res.text();
                })
            .then(async (result) => {
                const data = JSON.parse(result);
                this.tag_id = data.tag_attributed
                this.tag_name = data.tag_renamed
                this.matrice_id = data.matrice_attributed
                this.matrice_name = data.matrice_renamed
                this.capteur_amonniaque = data.capteur_amonniaque;
                this.capteur_amonniaque_name = data.amonniaque_renamed;
                this.capteur_vibration = data.capteur_vibration;
                this.capteur_vibration_name = data.vibration_renamed;
                this.capteur_sphere = data.capteur_sphere;
                this.capteur_sphere_name = data.sphere_renamed;
                this.capteur_environnement = data.capteur_environnement;
                this.capteur_environnement_name = data.environnement_renamed;
            })
            .catch(error => {
                console.log(error)
            });
        },
        
  }
}
</script>

<style scoped>
.account-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    margin-top: 120px;
	
}

.data-table-container {
	width: 80%;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: calc(95vh - 200px);
    overflow-y: auto;
}

.data-table-container-matrice {
	width: 80%;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: calc(95vh - 200px);
    overflow-y: auto;
    margin-bottom: 100px;
}


.title-account {
	color: var(--bleu);
	margin-bottom: 20px;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
    border: 1px solid var(--grisclair);
}

.data-table th, .data-table td {
    border: 1px solid var(--grisclair);
    padding: 12px 15px;
}

.data-table th {
    background-color: #afb1b0bb;
    color: var(--gris)
    ;
}

.data-table tbody tr:nth-of-type(even) {
    background-color: #f5f5f5;
}

.btn-modal {
    background-color: var(--bleu);
    color: white !important;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-modal:hover {
    background-color: var(--turquoise);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: var(--gris);
}

.close-icon:hover {
    color: var(--bleu);
}

.small-button {
    background-color: var(--bleu);
    color: white !important;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.small-button-reset{
    background-color: var(--vertfonce);
    color: white !important;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.small-button:hover {
    color: var(--violet);
}

</style>