<template>
<v-main class="padding100" >
		<v-container fluid fill-height>
			<v-row align-center justify-center>
				<v-col xs12 sm8 md4>
					<h1 class="blue-h">Liste des Lecteurs</h1>
					<v-data-table v-if="show == 1"
						:headers="headers"
						:items="tags"
						:items-per-page="5"
						class="elevation-1"
					>
					<template v-slot:item.action="{ item }">
						<v-btn @click="matrice_name = item ; dialog = true;">SUPPRIMER</v-btn>
					</template>
					<template v-slot:item.email="{ item }">
						<v-select
						:items="item.email ? item.email : ['No email']"
						label="Emails"
						chips
						></v-select>
					</template>
					<template v-slot:item.last_used="{ item }">
						{{ formatDate(item.last_used) }}
					</template>
					</v-data-table>
				</v-col>
			</v-row>
			<v-dialog v-model="dialog" max-width="290">
				<v-card>
					<v-card-title class="headline">Confirmation</v-card-title>
					<v-card-text>
						Êtes-vous sûr de vouloir supprimer le lecteur {{ matrice_name.matrice_id }} ? Elle sera dépossédée de tous les utilisateurs à qui elle est actuellement attribuée  
					</v-card-text>
					<v-card-actions>
						<v-btn color="blue darken-1" text @click="dialog = false">Non</v-btn>
						<v-btn color="blue darken-1" text @click="supressMatrice">Oui</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
</v-main>
	
</template>
<script>

import {getDurationCapteurs, getStockage, getDataLastId} from '../../../functions/get-data'
import { switchToken } from '../../../functions/test-token';
import moment from 'moment';
moment.locale('fr');
	export default {
		data(){
			return {
				show: 0,
				headers: [
				{
					text: 'id',
					align: 'start',
					value: 'id',
				},
				{ text: 'Actions', value: 'action', sortable: false },
				{ text: 'matrice name', value: 'matrice_id' },
					{ text: 'durée d\'utilisation', value: 'durée' },
					{ text: 'espace utilisé', value: 'stockage' },
					{ text: 'mail', value: 'email' },
					{ text: 'Dernière utilisation', value: 'last_used' }
					],
				tags: [
				{
					id: 1,
					tag_id: '',
					email: [''],
				},
				],
				dialog: false,
				matrice_name: '',
			}
		},

    async mounted(){
			await this.GetMatrice();
			await this.getDuration();
			await this.getStockage();
		},

	methods: {
        async GetMatrice() {
			const url = this.$api.getRESTApiUri() + `/get-matrice-id`;
			const accessToken = localStorage.getItem('accessToken');
			const headers = new Headers({
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accessToken}`
			});
			return fetch(url, { headers: headers })
			.then(res => {
				if (res.status === 401) {
					switchToken().then(() => {
					// Refaites la requête avec le nouveau token
					this.GetMatrice();
					});
				}
				return res.text();
			})
			.then((result) => {
				const parsedResult = JSON.parse(result);
				this.tags = parsedResult.filter((item) => item.matrice_id.startsWith('lct_'));
				this.getAllData();
			})
			.catch((error) => {
				console.log(error);
			});
		},

		async getDuration() {
			for (let i = 0; i < this.tags.length; i++) {
				const tag_id = this.tags[i].matrice_id;
				const total_duration  = await getDurationCapteurs(tag_id)
				this.tags[i].durée = await formatDuration(total_duration)
			}
		},
		
		async getStockage() {
			let results = [];
			for (let i = 0; i < this.tags.length; i++) {
				const tag_id = this.tags[i].matrice_id;
				const result = await getStockage(tag_id)
				if (result) {
				results.push(result);
				}
			}
			for (let i = 0; i < results.length; i++) {
				let duration = results[i] ? results[i][0].table_size : '';	
				// Affectation de la durée formatée à la propriété 'durée' de l'objet correspondant
				this.tags[i].stockage = duration;
			}
			this.show = 1;
		},
		async getAllData() {
			for (let i = 0; i < this.tags.length; i++) {
				const tag_id = this.tags[i].matrice_id;
				const data = await getDataLastId(tag_id)
				this.tags[i].last_used = data[0].received_on;
			}
		},
		async supressMatrice(){
			const accessToken = localStorage.getItem('accessToken')
			const url = this.$api.getRESTApiUri() + `/admin/supress-matrices`;
			return fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					tag_id: this.matrice_name.matrice_id,
					email: this.matrice_name.email
				}),
			})
			.then(res => {
				if (res.status === 401) {
					switchToken().then(() => {
						// Refaites la requête avec le nouveau token
						this.deleteUser();
					});
				}
				return res.text();
			})
			.then((response) => {
				this.tags = this.tags.filter(tag => tag.matrice_id !== this.matrice_name.matrice_id);
			this.dialog =false ;
			})
			 .catch((error) => {
				console.error('Error:', error);
				// Handle the error if needed
			});
		},
		
		formatDate(date) {
			return moment(date).format('LL, LT');
		},

	}
}
</script>