<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="10" lg="10" xl="8">
                <div class="plateformes-container">
                    <div class="list-container">
                        <ul class="row-around item-implant">
                            <li :class="{ 'active': button == 1, 'inactive': button != 1 }">
                                <button @click="button = 1"><h3>{{this.$t('pageMatrice.matrice[0].title')}}</h3></button>
                                <div :class="{ 'active': button == 1 }"></div>
                                <p>{{this.$t('pageMatrice.matrice[0].type')}}</p>
                            </li>
                            <li :class="{ 'active': button == 2, 'inactive': button != 2 }">
                                <button @click="button = 2"><h3>{{this.$t('pageMatrice.matrice[1].title')}}</h3></button>
                                <div :class="{ 'active': button == 2 }"></div>
                                <p>{{this.$t('pageMatrice.matrice[1].type')}}</p>
                            </li>
                            <li :class="{ 'active': button == 3, 'inactive': button != 3 }">
                                <button @click="button = 3"><h3>{{this.$t('pageMatrice.matrice[2].title')}}</h3></button>
                                <div :class="{ 'active': button == 3 }"></div>
                                <p>{{this.$t('pageMatrice.matrice[2].type')}}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="implant-container-inferior row-around">
                        <div class="part-1">
                            <div class="image-implant-container row-around">
                                <img src="@/assets/illustrations/MiceTrackPro.png" alt="" :class="{ 'active': button == 1 }">
                                <img src="@/assets/illustrations/MiceTrackLite.png" alt="" :class="{ 'active': button == 2 }">
                                <img src="@/assets/illustrations/MiceReader.png" alt="" :class="{ 'active': button == 3 }">
                            </div>
                        </div>
                        <div class="part-2">
                                <div class="description">
                                    <div class="information-implant" :class="{ 'active': button == 1 }">
                                        <h2 class="title-implant">{{ this.$t('pageMatrice.matrice[0].title')}}</h2>
                                        <p>{{ this.$t('pageMatrice.matrice[0].dimension')}}</p>
                                        <p>{{ this.$t('pageMatrice.matrice[0].texte')}}</p>
                                    </div>
                                    <div class="information-implant" :class="{ 'active': button == 2 }">
                                        <h2 class="title-implant">{{ this.$t('pageMatrice.matrice[1].title')}}</h2>
                                        <p>{{ this.$t('pageMatrice.matrice[1].dimension')}}</p>
                                        <p>{{ this.$t('pageMatrice.matrice[1].texte')}}</p>
                                    </div>
                                    <div class="information-implant" :class="{ 'active': button == 3 }">
                                        <h2 class="title-implant">{{ this.$t('pageMatrice.matrice[2].title')}}</h2>
                                        <p>{{ this.$t('pageMatrice.matrice[2].dimension')}}</p>
                                        <p>{{ this.$t('pageMatrice.matrice[2].texte')}}</p>
                                    </div>
                                    
                                </div>
                                <div class="detail"> 
                                    <details class="detailsElement" open :class="{ 'active': button == 3}">
                                        <summary class="row-between">{{this.$t('pageMatrice.accordionItems[9].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[9].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3}" >
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[0].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[0].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[1].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[1].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[2].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[2].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 }">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[4].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[4].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[5].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[5].content') }}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2 || button == 3 }">
                                        <summary class="row-between">{{this.$t('pageMatrice.accordionItems[6].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{this.$t('pageMatrice.accordionItems[6].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[7].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[7].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[8].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[8].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 3}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[10].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[10].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active':  button == 3}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[11].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[11].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active':  button == 3}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[12].title')}}</summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[12].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 3}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[13].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[13].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active':  button == 2}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[14].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[14].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[15].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[15].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active':  button == 3 }">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[16].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[16].content')}}</p>
                                    </details>
                                    <details class="detailsElement" :class="{ 'active': button == 1 || button == 2}">
                                        <summary class="row-between">{{ this.$t('pageMatrice.accordionItems[17].title')}}<v-icon class="fermé">mdi-chevron-right</v-icon><v-icon class="ouvert">mdi-chevron-down</v-icon></summary>
                                        <p>{{ this.$t('pageMatrice.accordionItems[17].content')}}</p>
                                    </details>
                                </div>
                                <!-- <div>
                                    <ul class="list-capteurs">
                                        <li>Matrice évolutive à vos besoins spécifiques</li>
                                        <li>Surface de la plateforme 100 cm² à 1 m²</li>
                                        <li>Mesure les positions XY de chaque animal et les emplacements des capteurs et actionneurs</li>
                                        <li>Transmet au cloud les données des Implants et Capteurs</li>
                                        <li>Transmet les ordres aux Actionneurs</li>
                                        <li>Implant Activité / PPG</li>
                                        <li>Fonctionnement temps réel et 24/24</li>
                                        <li>Accueille 5 souris + 2 capteurs + 3 actionneurs</li>
                                        <li>Fonctionnement sous 5v (USB ou secteur)</li>
                                    </ul>
                                </div> -->
                                <div class="button-position">
                                    <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                                </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    data() {
        return {
            button: 1,
        };
    },
    methods: {
         toggleAccordion(index) {
            this.$set(this.pageMatrice.accordionItems, index, {
                ...this.pageMatrice.accordionItems[index],
                active: !this.pageMatrice.accordionItems[index].active,
            });
        }
    },
};
</script>

<style scoped>

ul {
    padding-left: 0px !important;
}

.plateformes-container {
    border-radius: 25px;
    background-color: var(--white);
    padding: 20px;
    min-width: fit-content;
}

.list-container {
    margin-top: 25px;
    position: sticky;
    top: 0px;
    background-color: var(--white);
    z-index: 1000;
}

.item-implant li {
    text-align: center;
}

.item-implant button:hover {
    opacity: 0.7;
}

.item-implant li div {
    width: 0px;
    height: 3px;
    background-color: #000;
    transition: width 0.5s ease; /* Animation sur la hauteur */
}

.item-implant li div.active {
    width: 100%; /* Hauteur finale lors de l'animation */
}

.implant-container-inferior {
    margin-top: 55px;
    height: fit-content;
}
.part-1 {
    width: 45%;
}

.part-2 {
    width: 45%;
}

.tilte-part {
    margin-bottom: 15px !important;
}

.image-implant-container {
    position: sticky;
    top: 120px;
    text-align: center;
    align-content: center;
}

.image-implant-container img {
    position: relative;
    z-index: 2;
    width: 80%;
    display: none;
}

.image-implant-container img.active {
    display: block;
}


.background-capteurs {
    border-radius: 20px;
    border: solid 1px #333;
    width: 100%;
    min-width: fit-content;
    padding: 20px;
}

.button-position {
    display: flex;
    justify-content: right;
    margin-top: 50px;
}


.information-implant {
    margin-top: 30px;
    animation: fadeInUp 0.5s ease-in-out forwards;
    display: none;
}

.information-implant.active {
    display: block;
}

.sous-titre{ 
    display: none;
}

.detail div {
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
}

summary{
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  list-style: none;
  padding-bottom: 20px;
  padding-top: 10px;
}
details {
    overflow: hidden;
    cursor: pointer;
    border-bottom: solid 1px var(--noirdoux);
}
details p {
  animation: fadeInlow 0.3s ease-in-out;
  animation-play-state: paused;
}

details[open] p {
  animation-play-state: running;
}
details .ouvert {
  display: none;
}
details .fermé {
  display: block;
}
details[open] .ouvert {
  display: block;
}
details[open] .fermé {
  display: none;
}

.detailsElement{
    width: 0;
    height: 0;
}
.detailsElement.active{
    width: auto;
    height: auto;
}

.list-capteurs{
    list-style: disc;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInlow {
    0% {
        opacity: 0.5;
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}


@media (max-width: 960px) {
    .implant-container {
        background-color: transparent;
        border: none;
        padding: 0;
    }
    .implant-container-inferior {
        margin-top: 50px;
    }
    
    .item-implant li {
        transition: transform 0.3s ease, opacity 0.3s ease;
    }
    .item-implant li p{
        display: none;
    }
    .item-implant li.inactive {
        transform: scale(0.7); /* Réduit la taille des éléments inactifs */
        opacity: 0.5; /* Rend les éléments inactifs moins opaques */
    }

    .item-implant li.active {
        transform: scale(1); /* Taille normale de l'élément actif */
        opacity: 1; /* Pleine opacité pour l'élément actif */
    }

    .item-implant button {
        font-weight: 400;
        font-size: 28px;
    }
    .sous-titre{ 
        display: block;
        text-align: center
    }
    summary{
        font-size: 20px;
    }
    .item-implant li.active {
        display: block;
    }
    .implant-container-inferior {
        display: block;
    }
    .part-2,
    .part-1 {
        width: 100%;
    }

    .capteurs {
        margin-top: 50px;
    }
    .description {
        margin-top: 50px;
    }
    .background-capteurs {
        background-color: #fff;
    }
    .image-implant-container {
        margin-top: 50px;
    }
    .image-implant-container img {
        width: 80%;
    }
    .tilte-part {
        text-align: center;
    }

    .button-position {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 20px;
    }
}

</style>