<template>
    <div>
        <nav-bar/>
        <accueil-image/>
        <bulle-contact/>
        <accueil-presentation class=" padding-resp mt100"/>
        <accueil-fonctionnement class="padding-resp-2 mt100"/>
        <accueil-benefices class="padding-resp mt100"/>
        <avantages class="padding-resp mt100"/>
        <language-popup/>
        <foot class="mt100"/>
    </div>
</template>

<script>
import NavBar from '../../components/NavBar.vue';
import AccueilImage from '../../components/PageAccueil/AccueilImage.vue';
import AccueilPresentation from '../../components/PageAccueil/AccueilPresentation.vue';
import AccueilBenefices from '../../components/PageAccueil/AccueilBenefices.vue';
import AccueilFonctionnement from '../../components/PageAccueil/AccueilFonctionnement.vue';
import foot from '@/components/FooterBis.vue'
import avantages from '../../components/PageAccueil/AccueilAvantages.vue';
import BulleContact from '../../components/BulleContact.vue';
import LanguagePopup from '../../components/LanguagePopup.vue';
export default{
    components: {
        NavBar,
        AccueilPresentation,
        AccueilBenefices,
        AccueilFonctionnement,
        AccueilImage,
        avantages,
        LanguagePopup,
        foot,
        BulleContact,
    }
}
</script>