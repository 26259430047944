<template>
  <div class="advantages-section">
    <h2>{{ $t('page-home.advantages.title') }}</h2>
    <div class="advantages">
      <div 
        class="advantage" 
        v-for="(advantage, index) in advantages"
        :key="index" 
        :data-aos="'fade-up'" 
        :data-aos-delay="index * 100"
      >
        <img :src="advantage.icon" :alt="$t(`page-home.advantages.items.${index}.alt`)" />
        <h3>{{ $t(`page-home.advantages.items.${index}.title`) }}</h3>
        <p>{{ $t(`page-home.advantages.items.${index}.description`) }}</p>
      </div>
    </div>
  </div>
</template>




<script>
export default {
  data() {
    return {
      advantages: [
        {
            icon: require('@/assets/illustrations/SurveillanceContinue.png'),
        },
        {
            icon: require('@/assets/illustrations/AnalyseSanté.png'),
        },
        {
            icon:  require('@/assets/illustrations/BienEtre.png'),
        },
        {
            icon: require('@/assets/illustrations/AnalyseCloud.png'),
        },
        {
            icon:  require('@/assets/illustrations/CageIntelligente.png'),
        }
      ]
    };
  },
};
</script>

<style scoped>
.advantages-section {
  text-align: center;
  margin: 50px 0;
  padding: 20px;
  border-radius: 15px;
}

.advantages-section h2{
  margin-bottom: 50px;
}
.advantages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
}
.advantage {
  width: 300px;
  padding: 20px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.advantage:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}
.advantage img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.advantage h3 {
  font-weight: 600;
  font-size: 24px;
  color: var(--bleu);
  margin-bottom: 15px;
}
.advantage p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: var(--noirdoux);
}
@media (max-width: 768px) {
  .advantages {
    flex-direction: column;
    align-items: center;
  }
}
</style>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css" />